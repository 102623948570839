<template>
    <div :class="class">
        <FloatLabel class="w-full">
            <slot :id="id" :invalid="invalid"/>
            <label :for="id">{{ label }}</label>
        </FloatLabel>
        <small class="text-red-500">{{ errors }}</small>
    </div>
</template>

<script>
import { nanoid } from 'nanoid';

export default {
    props: {
        label: {
            type: String,
            required: false,
            default: null
        },
        class: {
            type: String,
            required: false,
            default: 'gap-2 w-full',
        },
        name: {
            type: String,
            required: false,
            default: null
        },
        errors: {
            type: [Array, String],
            required: false,
            default: null
        }
    },
    computed: {
        id() {
            const id = nanoid(5);
            if (this.name)
                return this.name + "-" + id;
            return id;
        },
        invalid() {
            return this.errors ? true : false;
        }
    },    
}
</script>
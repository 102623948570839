<template>
    <FileUpload @select="onFileSelect" @remove="onFileRemove" customUpload :multiple="true" :accept="accept" :maxFileSize="1000000">
        <template #header="{ chooseCallback, uploadCallback, clearCallback, files }">
            <div class="flex flex-wrap justify-between items-center flex-1 gap-4">
                <div class="flex gap-2">
                    <Button @click="chooseCallback()" icon="pi pi-images" rounded outlined severity="secondary"></Button>
                    <Button @click="clearAll(clearCallback)" icon="pi pi-times" rounded outlined severity="danger" :disabled="!files || files.length === 0"></Button>
                </div>
                <div>
                    <Button class="self-end" @click="downloadAll()" icon="pi pi-download" rounded outlined severity="secondary" :disabled="!preloaded || preloaded.length === 0 || !leadId"></Button>
                </div>
            </div>
        </template>

        <template #content="{ files, uploadedFiles, removeUploadedFileCallback, removeFileCallback }">
            <div class="flex flex-col gap-8 pt-4">
                <template v-if="files.length > 0 || Object.keys(preloaded)?.length > 0">
                    <div>
                        <div class="grid grid-cols-1 gap-4 sm:grid-cols-2 md:grid-cols-3">
                            <template v-if="files.length > 0">
                                <div v-for="(file, index) of files" :key="file.name + file.type + file.size" class="flex flex-col justify-center border border-surface">
                                    <Image v-if="file.type?.startsWith('image/')" :alt="file.name" :src="file.objectURL" imageClass="!object-cover !object-center !w-full !h-40 !max-w-full" preview />
                                    <Image v-else :alt="file.name" :src="getPlaceholder(file)" class="!h-40 !flex" imageClass="!object-center !m-auto !max-w-full" />
                                    <div class="grid grid-cols-5 content-center items-center p-1">
                                        <div class="flex flex-col col-span-4">
                                            <span class="font-semibold text-ellipsis max-w-60 whitespace-nowrap overflow-hidden">{{ file.name }}</span>
                                            <div>{{ formatSize(file.size) }}</div>
                                        </div>
                                        
                                        <Button class="justify-self-end" icon="pi pi-times" @click="onRemoveTemplatingFile(file, removeFileCallback, index)" outlined rounded severity="danger" />
                                    </div>
                                </div>
                            </template>
                            <template v-if="Object.keys(preloaded)?.length > 0">
                                <div v-for="(file, uuid) of preloaded" :key="file.uuid" class="flex flex-col justify-center border border-surface">
                                    <Image v-if="isImage(file)" :alt="file.file_name" :src="file.original_url" imageClass="!object-cover !object-center !w-full !h-40 !max-w-full" preview />
                                    <Image v-else :alt="file.file_name" :src="getPlaceholder(file)" class="!h-40 !flex" imageClass="!object-center !m-auto !max-w-full" />
                                    <div class="grid grid-cols-5 content-center items-center p-1">
                                        <div class="flex flex-col col-span-4">
                                            <span class="font-semibold text-ellipsis max-w-60 whitespace-nowrap overflow-hidden">{{ file.name }}</span>
                                            <div>{{ formatSize(file.size) }}</div>
                                        </div>
                                        
                                        <Button class="justify-self-end" icon="pi pi-times" @click="onRemovePreloadedFile(file, uuid)" outlined rounded severity="danger" />
                                    </div>
                                </div>
                            </template>
                            <!-- <img :src="externalFiles[0]" width="250px" alt=""> -->
                        </div>
                    </div>
                </template>
                <div v-else class="flex items-center justify-center flex-col">
                    <i class="pi pi-cloud-upload !border-2 !rounded-full !p-8 !text-4xl !text-muted-color" />
                    <p class="mt-6 mb-0">{{ $t('Drag and drop files to here to upload.') }}</p>
                </div>
            </div>
        </template>
    </FileUpload>
</template>

<script>
import { router } from "@inertiajs/vue3";

export default {
    props: {
        preloaded: {
            type: Object,
            required: false,
            default: []
        },
        files: {
            type: Array,
            required: true
        },
        remove: {
            type: Array, 
            default: []
        },
        accept: {
            type: String,
            required: false,
            default: 'image/*'
        },
        leadId: {
            type: Number,
            required: false,
            default: null
        }
    },
    setup() {
        const extensionToMime = {
            doc: 'application/msword',
            docx: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
            xls: 'application/vnd.ms-excel',
            xlsx: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
            pdf: 'application/pdf',
            txt: 'text/plain'
        };

        return { extensionToMime };
    },
    methods: {
        onFileSelect(event) {
            this.$emit('update:files', event.files);
        },
        onFileRemove(event) {
            this.$emit('update:files', event.fieles);
        },
        onRemoveTemplatingFile(file, removeFileCallback, index) {
            removeFileCallback(index);
        },
        onRemovePreloadedFile(file, uuid) {
            delete this.preloaded[uuid];
            this.$emit('update:remove', [uuid].concat(this.remove));
        },
        clearAll(clearCallback) {
            clearCallback();
            this.$emit('update:files', []);
        },
        formatSize(bytes) {
                const k = 1024;
                const dm = 3;
                const sizes = this.$primevue.config.locale.fileSizeTypes;

                if (bytes === 0) {
                    return `0 ${sizes[0]}`;
                }

                const i = Math.floor(Math.log(bytes) / Math.log(k));
                const formattedSize = parseFloat((bytes / Math.pow(k, i)).toFixed(dm));

                return `${formattedSize} ${sizes[i]}`;
            },
            async urlToFile(url, fileName, mimeType) {
            const response = await fetch(url);
            const blob = await response.blob(); // Get the binary data
            const file = new File([blob], fileName, { type: mimeType }); // Create a File object
            return file;
        },
        isImage(file) {
            return file.hasOwnProperty('extension') && !this.extensionToMime.hasOwnProperty(file?.extension);
        },
        getPlaceholder(file) {
            let fileType = file?.type;
            if (!fileType && file.hasOwnProperty('extension'))
                fileType = this.extensionToMime[file.extension];

            if (fileType === 'application/msword' || fileType === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document') {
                return '/img/icons/files/word.png';
            } else if (fileType === 'application/vnd.ms-excel' || fileType === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet') {
                return '/img/icons/files/excel.png';
            } else if (fileType === 'application/pdf') {
                return '/img/icons/files/pdf.png';
            } else if (fileType === 'text/plain') {
                return '/img/icons/files/txt.png';
            } else {
                return '/img/icons/files/txt.png';
            }
        },
        //TODO:Deprecated
        async addPreloadedFile(url) {
            if (url) {
                const response = await fetch(url);
                const blob = await response.blob();
                const fileName = url.split('/').pop(); // Extract file name from URL
                const file = new File([blob], fileName, { type: blob.type });
                const objectURL = URL.createObjectURL(file);

                // Push the file into the reactive preloadedFiles array
                this.preloadedFiles.push({
                    name: file.name,
                    size: file.size,
                    type: file.type,
                    objectURL: objectURL,
                });
            }
        },
        downloadAll() {
            if (this.leadId)
                window.open(route('lead.media.all', {lead: this.leadId}), '_blank');
        }
    }
}
</script>
<template>
    <div class="!p-4 card !mb-4">
      <Galleria class="border-transparent" v-model:activeIndex="activeIndex" v-model:visible="displayCustom" :value="filesArray" :responsiveOptions="responsiveOptions"
          containerStyle="max-width: 850px" :showItemNavigators="true" :fullScreen="true" :showThumbnails="false">
          <template #item="slotProps">
              <img :src="slotProps.item.original_url" :alt="slotProps.item?.alt"/>
          </template>
      </Galleria>

      <div class="flex flex-col gap-8 pt-4">
        <div class="grid grid-cols-1 gap-4 sm:grid-cols-2 md:grid-cols-3">
          <template v-if="Object.keys(files)?.length > 0">
              <div v-for="(file, index) of filesArray" :key="file.index" class="flex flex-col justify-center border border-surface">
                <span class="p-image p-component p-image-preview cursor-pointer" @click="imageClick(index)">
                  <img :alt="file.file_name" :src="file.original_url" class="hover:shadow-md !object-cover !object-center !w-full !h-40 !max-w-full" />
                  <button type="button" class="p-image-preview-mask">
                    <!-- TODO: indicator* deprecated since v4.0-->
                    <slot :name="'previewicon'">
                        <component :is="'EyeIcon'" class="p-image-preview-icon"/>
                    </slot>
                </button>
                </span>
                  <div class="grid grid-cols-5 content-center items-center p-1">
                      <div class="flex flex-col col-span-4">
                          <span class="font-semibold text-ellipsis max-w-60 whitespace-nowrap overflow-hidden">{{ file.name }}</span>
                          <div>{{ formatSize(file.size) }}</div>
                      </div>
                  </div>
              </div>
          </template>
        </div>
      </div>
    </div>
</template>


  
<script>
import EyeIcon from '@primevue/icons/eye';

export default {
  components: {
    EyeIcon: EyeIcon,
  },
  props: {
    files: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      displayCustom: false,
      activeIndex: 0,
      responsiveOptions: [
        {
          breakpoint: '1024px',
          numVisible: 5
        },
        {
            breakpoint: '768px',
            numVisible: 3
        },
        {
            breakpoint: '560px',
            numVisible: 1
        }
      ]
    }
  },
  computed: {
    filesArray() {
      return Object.keys(this.files).map(key => ({
        ...this.files[key]
      }));
    }
  },
  methods: {
    imageClick(index) {
      this.activeIndex = index;
      this.displayCustom = true;
    },
    formatSize(bytes) {
            const k = 1024;
            const dm = 3;
            const sizes = this.$primevue.config.locale.fileSizeTypes;

            if (bytes === 0) {
                return `0 ${sizes[0]}`;
            }

            const i = Math.floor(Math.log(bytes) / Math.log(k));
            const formattedSize = parseFloat((bytes / Math.pow(k, i)).toFixed(dm));

            return `${formattedSize} ${sizes[i]}`;
        },
        async urlToFile(url, fileName, mimeType) {
        const response = await fetch(url);
        const blob = await response.blob(); // Get the binary data
        const file = new File([blob], fileName, { type: mimeType }); // Create a File object
        return file;
    },
  }
}
</script>
<style lang="css" scoped>
.p-galleria{
  border:none !important;
}

.p-image-preview {
    position: relative;
    display: inline-flex;
    line-height: 0;
}

.p-image-preview:hover > .p-image-preview-mask {
    opacity: 1;
    cursor: pointer;
    background: rgba(0, 0, 0, 0.4);
}

.p-image-preview-mask {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    opacity: 0;
    transition: opacity 0.3s;
    border: 0 none;
    padding: 0;
    cursor: pointer;
    color: #e2e8f0;
    transition: background 0.2s;
}
</style>
<template>
    <Head :title="$t('Users')"/>
    <ConfirmPopup></ConfirmPopup>

    <Toolbar class="mb-4 !border-none">
        <template #start id="tollbar-start">
            <div class="font-medium text-xl">{{ $t('Users') }}</div>
        </template>

        <template #center>
            <IconField>
                <InputIcon>
                    <i class="pi pi-search" />
                </InputIcon>
                <InputText :placeholder="$t('search')" v-model="search"/>
            </IconField>
        </template>

        <template #end>
            <Button as="router-link" :label="$t('create')" :to="route('user.create')"></Button>
        </template>
    </Toolbar>

    <div class="card">
        <DataView :value="users" :layout="layout" :rows="50" :paginator="true">
            <template #list="slotProps">
                <div class="flex flex-col">
                    <div v-for="(item, index) in slotProps.items" :key="item.id">
                        <div class="flex flex-row sm:flex-row sm:items-center p-4 gap-4" :class="{ 'border-t border-surface': index !== 0 }">
                            <div class="md:w-20 relative">
                                <Avatar :image="item.avatar" class="mr-2" size="xlarge" shape="square" :pt="{ image: { class: 'rounded' } }"/>
                            </div>
                            <div class="flex flex-col md:flex-row justify-between md:items-center flex-1 gap-6">
                                <div class="flex flex-row md:flex-col justify-between items-start gap-2">
                                    <div class="flex flex-row items-center gap-2">
                                        <div class="text-lg font-medium">{{ item.full_name }}</div>
                                        <a v-if="item.facebook" :href="item.facebook"><i class="pi pi-facebook !text-xl"></i></a>
                                    </div>
                                </div>
                                <div class="flex flex-col md:items-end gap-8">
                                    <div class="flex flex-row-reverse md:flex-row gap-2">
                                        <Button v-if="hasRole('Admin')" severity="contrast" as="router-link" icon="pi pi-users" outlined rounded :to="route('user.login-as', item.id)"></Button>
                                        <Button as="router-link" icon="pi pi-pencil" outlined rounded :to="route('user.edit', item.id)"></Button>
                                        <Button icon="pi pi-trash" outlined rounded severity="danger" @click="destroy($event, item.id)"></Button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </template>
        </DataView>
    </div>


</template>


<script>
import { Link, Head, router } from "@inertiajs/vue3";
import PrimeLayout from "../../Layouts/PrimeLayout.vue";
import { usePermissions } from '../../Composables/usePermissions';
import { useConfirm } from 'primevue/useconfirm';
import { trans } from 'laravel-vue-i18n';

export default {
    layout: PrimeLayout,
    components: {
        Link, Head
    },
    props: {
        users: Object,
        searchName: {
            type: String,
            required: false,
            default: null
        }
    },
    setup() {
        const { hasRole, hasPermission } = usePermissions();
        const confirmPopup = useConfirm();
        return { hasRole, hasPermission, confirmPopup };
    },
    data() {
        return {
            options: ['list', 'grid'],
            layout: 'list',
            search: this.searchName
        }
    },
    methods: {
        destroy(event, id) {
            this.confirmPopup.require({
                target: event.currentTarget,
                message: 'Подтвердить удаление?',
                icon: 'pi pi-info-circle',
                rejectProps: {
                    label: 'Отменить',
                    severity: 'secondary',
                    outlined: true
                },
                acceptProps: {
                    label: 'Да',
                    severity: 'danger'
                },
                accept: () => {
                    router.delete(route('user.destroy', id));
                    this.toast.success(trans('deleted'));
                }
            });
        },
    },
    watch: {
        search(val) {
            let query = {};
            if (val) {
                query.search = val;
            }
            router.get(route('user.index'), query, {
                preserveState: true
            });
        }
    },
}

</script>
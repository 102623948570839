<template>
    <Head :title="$t('create')"/>

    <Toolbar class="mb-4 !border-none">
        <template #start id="tollbar-start">
            <div class="font-medium text-xl">{{ facility.name }}</div>
        </template>

        <template #end>
            <Button :label="$t('save')" @click.prevent="form.put(route('facility.update', facility.id))"></Button>
        </template>
    </Toolbar>

    <Fluid>
        <div class="flex mt-8">
            <div class="card flex flex-col gap-4 w-full">
                <div class="grid grid-cols-1 md:grid-cols-2 gap-y-7 gap-x-4">
                    <div class="gap-2 w-full col-span-2">
                        <FloatLabel class="w-full">
                            <InputText id="name" v-model="form.name" :invalid="errors.name ? true : false" autocomplete="off"/>
                            <label for="name">{{ $t('v_facilities.name') }}</label>
                        </FloatLabel>
                        <small class="text-red-500">{{ errors.name }}</small>
                    </div>
                    <div class="gap-2 w-full">
                        <FloatLabel class="w-full">
                            <InputText id="nip" v-model="form.nip" :invalid="errors.nip ? true : false" autocomplete="off"/>
                            <label for="nip">{{ $t('v_facilities.nip') }}</label>
                        </FloatLabel>
                        <small class="text-red-500">{{ errors.nip }}</small>
                    </div>
                    <div class="gap-2 w-full">
                        <FloatLabel class="w-full">
                            <InputText id="official_name" v-model="form.official_name" :invalid="errors.official_name ? true : false" autocomplete="off"/>
                            <label for="official_name">{{ $t('v_facilities.official_name') }}</label>
                        </FloatLabel>
                        <small class="text-red-500">{{ errors.nip }}</small>
                    </div>
                    <div class="gap-2 w-full md:col-span-2">
                        <FloatLabel class="w-full">
                            <GoogleAutocomplete id="google_address" v-model="form.google_address"/>
                            <label for="google_address">{{ $t('v_facilities.google_address') }}</label>
                        </FloatLabel>
                    </div>
                    <div class="gap-2 w-full">
                        <FloatLabel class="w-full">
                            <InputText id="postcode" v-model="form.postcode" :invalid="errors.street ? true : false" autocomplete="off"/>
                            <label for="postcode">{{ $t('v_facilities.postcode') }}</label>
                        </FloatLabel>
                        <small class="text-red-500">{{ errors.postcode }}</small>
                    </div>
                    <div class="gap-2 w-full">
                        <FloatLabel class="w-full">
                            <InputText id="city" v-model="form.city" :invalid="errors.city ? true : false" autocomplete="off"/>
                            <label for="city">{{ $t('v_facilities.city') }}</label>
                        </FloatLabel>
                        <small class="text-red-500">{{ errors.city }}</small>
                    </div>
                    <div class="gap-2 w-full">
                        <FloatLabel class="w-full">
                            <InputText id="street" v-model="form.street" :invalid="errors.street ? true : false" autocomplete="off"/>
                            <label for="street">{{ $t('v_facilities.street') }}</label>
                        </FloatLabel>
                        <small class="text-red-500">{{ errors.street }}</small>
                    </div>
                    <div class="gap-2 w-full">
                        <FloatLabel class="w-full">
                            <InputText id="street_number" v-model="form.street_number" :invalid="errors.street_number ? true : false" autocomplete="off"/>
                            <label for="street_number">{{ $t('v_facilities.street_number') }}</label>
                        </FloatLabel>
                        <small class="text-red-500">{{ errors.street_number }}</small>
                    </div>
                </div>
            </div>
        </div>
    </Fluid>
</template>

<script>
import { useForm, Head } from '@inertiajs/vue3'
import { usePermissions } from '../../Composables/usePermissions';

import PrimeLayout from '../../Layouts/PrimeLayout.vue';
import Alert from '../../Components/Alert.vue';
import GoogleAutocomplete from '../../Components/Form/GoogleAutocomplete.vue';

export default {
    layout: PrimeLayout,
    components: {
        Head,
        Alert,
        GoogleAutocomplete
    },
    props: {
        facility: Object,
        errors: Object
    },
    setup(props) {
        const { hasRole, hasPermission } = usePermissions();

        const form = useForm({
            name: props.facility.name,
            official_name: props.facility.official_name,
            nip: props.facility.nip,
            google_address: props.facility.google_address,
            city: props.facility.city,
            street: props.facility.street,
            street_number: props.facility.street_number,
            postcode: props.facility.postcode
        });

        return { form, hasRole, hasPermission };
    },
    watch: {
        'form.google_address': function (val) {
            this.form.street = val.route;
            this.form.postcode = val.postal_code;
            this.form.city = val.locality;
            this.form.street_number = val.street_number;
        }
    }
}
</script>
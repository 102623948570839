<template>
    <div>
        <Head :title="$t('create')"/>

        <Toolbar class="mb-4 !border-none">
            <template #start id="tollbar-start">
                <div class="font-medium text-xl">{{ $t('Lead') }}</div>
            </template>

            <template #end>
                <Button :label="$t('save')" @click.prevent="submitForm"></Button>
            </template>
        </Toolbar>

        <Fluid>
            <Stages :small="mobile" :stages="stages" v-model:stage="form.stage_id"/>

            <div class="mt-0 md:grid md:grid-cols-2 md:gap-4">

                <Tabs v-model:value="currentTab">
                    <TabList pt:tabList:class="!justify-center md:!justify-start">
                        <Tab value="information">{{ $t('leads.information') }}</Tab>
                        <Tab value="documents">{{ $t('leads.documents') }}</Tab>
                        <Tab v-if="mobile" value="chat">{{ $t('leads.chat') }}</Tab>
                    </TabList>
                    <TabPanels :class="currentTab == 'chat' ? '!px-1' : ''">
                        <TabPanel value="information">
                            
                            <div class="grid grid-flow-row gap-y-7 mt-4 gap-x-4 md:grid-cols-2">
                                <TextInput :label="$t('leads.full_name')" v-model="form.full_name" :errors="errors.full_name"/>
                                <Date :label="$t('leads.birthday')" v-model="form.birthday" :errors="errors.birthday" fluid/>

                                <InputWrap class="md:col-span-2" :errors="errors.sex">
                                    <template v-slot="{ id, invalid }">
                                        <SelectButton v-model="form.sex" :options="sexOptions" optionLabel="icon" optionValue="value" dataKey="value" aria-labelledby="custom" :invalid="invalid" class="justify-center w-full md:justify-start md:w-auto">
                                            <template #option="slotProps">
                                                <span :class="slotProps.option.class" translate="no">{{ slotProps.option.icon }}</span>
                                            </template>
                                        </SelectButton>
                                    </template>
                                </InputWrap>

                                <Repeater class="grid grid-flow-row gap-7 md:col-span-2" name="phones" v-model="form.phones" :errors="errors">
                                    <template v-slot="{ item, repeaterUpdate, index, error }">
                                        <PhoneInput v-model="item['phone']" :label="$t('leads.phones')" :repeater-index="index" :repeater-update="repeaterUpdate" :errors="error?.phone" autocomplete="off"></PhoneInput>
                                    </template>
                                </Repeater>

                                <InputWrap :label="$t('leads.city')" :errors="errors.city_id">
                                    <template v-slot="{id, invalid}">
                                        <AutoComplete  
                                            :invalid="invalid"
                                            :inputId="id"
                                            v-model="this.form.city" 
                                            :suggestions="cities" 
                                            optionLabel="full_name" 
                                            optionValue="id" 
                                            class=""
                                            forceSelection
                                            :loading="citySelectLoading"
                                            @complete="fetchCities"  
                                        />
                                    </template>
                                </InputWrap>

                                <InputWrap :errors="errors.ready_to_move">
                                    <template v-slot="{id, invalid}">
                                        <ToggleButton 
                                            v-model="form.ready_to_move"
                                            :id="id" 
                                            :invalid="invalid"
                                            onIcon="pi pi-check" 
                                            offIcon="pi pi-times" 
                                            onLabel="Готов к переезду" 
                                            offLabel="Не готов к переезду" 
                                            class="w-full"
                                        />
                                    </template>
                                </InputWrap>

                                <InputWrap :label="$t('leads.preferred_job')" :errors="errors.preferred_jobs">
                                    <template v-slot="{id, invalid}">
                                        <MultiSelect 
                                            :inputId="id"
                                            :invalid="invalid"
                                            v-model="form.preferred_jobs" 
                                            display="chip" 
                                            :options="preferred_jobs" 
                                            optionLabel="name"
                                            optionValue="id" 
                                            filter 
                                        />
                                    </template>
                                </InputWrap>

                                <div>
                                    <Button type="button" label="Подходящие вакансии" @click="toggleVacancies" :badge="`${recomendedVacancies.length}`" badgeClass="!leading-[0.5rem] !ms-[-2px]"/>
                                    <Popover ref="vacancies">
                                        <div class="flex flex-col gap-4">
                                            <div>
                                                <ul class="list-none p-0 m-0 flex flex-col">
                                                    <li v-for="vacancy in recomendedVacancies" :key="vacancy?.id" class="flex items-center gap-2 px-2 py-3 hover:bg-emphasis cursor-pointer rounded-border" @click="selectRecomended(vacancy)">
                                                        <div>
                                                            <span class="font-medium">{{ vacancy.title }}</span>
                                                            <div class="text-sm text-surface-500 dark:text-surface-400"></div>
                                                        </div>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </Popover>
                                </div>

                                <InputWrap :label="$t('leads.vacancy')" :errors="errors.vacancy_id">
                                    <template v-slot="{id, invalid}">
                                        <AutoComplete  
                                            :invalid="invalid"
                                            :inputId="id"
                                            v-model="this.form.vacancy" 
                                            :suggestions="vacancies" 
                                            optionLabel="title" 
                                            optionValue="id" 
                                            class=""
                                            forceSelection
                                            :loading="vacancySelectLoading"
                                            @complete="fetchVacancies"  
                                        />
                                    </template>
                                </InputWrap>

                                <Date :label="$t('leads.work_start_date')" v-model="form.first_workday" :errors="errors.first_workday" fluid/>

                                <InputWrap :label="$t('leads.google_address')" :errors="errors.google_address">
                                    <template v-slot="{id, invalid}">
                                        <GoogleAutocomplete :inputId="id" v-model="form.google_address" :invalid="invalid" />
                                    </template>
                                </InputWrap>

                                <InputWrap :label="$t('leads.recruiter')" :errors="errors.responsible_id" v-if="hasRole('Manager') || hasRole('Admin')">
                                    <template v-slot="{id, invalid}">
                                        <Select 
                                            :invalid="invalid"
                                            :inputId="id"
                                            v-model="form.responsible_id"
                                            :options="responsibles"
                                            optionLabel="full_name"
                                            optionValue="id"
                                            class=""
                                        />
                                    </template>
                                </InputWrap>

                            </div>

                        </TabPanel>
                        <TabPanel value="documents">
                            <div class="card flex flex-col gap-4 w-full !mb-0">
                                <Dropzone v-model:files="form.documents" :accept="'.doc,.docx,.xls,.xlsx,.pdf,.txt,image/*'"></Dropzone>
                            </div>
                        </TabPanel>
                        <TabPanel value="chat" class="!px-0">
                            <Comments v-if="mobile" v-model="form.comments" class="max-h-[50dvh] !h-[50dvh]"></Comments>
                        </TabPanel>
                    </TabPanels>
                </Tabs>
                
                <Comments v-if="!mobile" v-model="form.comments" class="max-h-[60vh] !h-[60vh]"></Comments>
                
            </div>
        </Fluid>
    </div>
</template>

<script>
import { breakpointsTailwind, useBreakpoints } from '@vueuse/core';
import { useForm, Head, Link } from '@inertiajs/vue3'
import { usePermissions } from '../../Composables/usePermissions';

import PrimeLayout from '../../Layouts/PrimeLayout.vue';
import Alert from '../../Components/Alert.vue';
import { usePage } from '@inertiajs/vue3'

import { TextInput, Date, PhoneInput, InputWrap } from "../../Components/PrimeForm";
import GoogleAutocomplete from '../../Components/Form/GoogleAutocomplete.vue';
import Dropzone from '../../Components/PrimeForm/Dropzone.vue';
import Repeater from '../../Components/Form/Repeater.vue';
import Comments from './Comments.vue';
import Stages from './Stages.vue';

export default {
    layout: PrimeLayout,
    components: {
        Alert,
        Head,
        Link,
        TextInput,
        Date,
        PhoneInput,
        InputWrap,
        Dropzone,
        Repeater,
        Comments,
        Stages,
        GoogleAutocomplete
    },
    props: {
        stages: Object,
        preferred_jobs: Array,
        errors: Object,
        responsibles: Array,
    },
    setup() {
        const page = usePage();
        const { hasRole, hasPermission } = usePermissions();
        const form = useForm({
            stage_id: 1,
            full_name: null,
            birthday: null,
            sex: null,
            phones: [],
            google_address: null,
            city: null,
            city_id: null,
            ready_to_move: false,
            preferred_jobs: [],
            vacancy: null,
            first_workday: null,
            documents: [],
            comments: null,
            responsible_id: page.props.auth.user.id
        });

        const sexOptions = [
            { 
                icon: 'male',
                class: 'material-symbols-outlined text-blue-600',
                value: 1
            },
            { 
                icon: 'female',
                class: 'material-symbols-outlined text-pink-600',
                value: 2
            },
            { 
                icon: 'family_restroom',
                class: 'material-symbols-outlined text-indigo-600',
                value: 3
            }
        ];

        const breakpoints = useBreakpoints(breakpointsTailwind);
        const mobile = breakpoints.smallerOrEqual('md');

        return { form, sexOptions, hasRole, hasPermission, breakpoints, mobile };
    },
    data() {
        return {
            currentTab: "information",
            recomendedVacancies: [],
            vacancies: [],
            vacancySelectLoading: false,
            cities: [],
            citySelectLoading: false,
        };
    },
    methods: {
        submitForm(){
            this.form
                .transform((data) => (this.prepareItem(data)))
                .post(route('lead.store'),
                    { onError: errors => { this.currentTab = 'information'; } }
                );
        },
        prepareItem(data) {
            data.vacancy_id = data.vacancy?.id ?? null;
            data.city_id = data.city?.id ?? null;

            return data;
        },
        toggleVacancies(event) {
            if (this.recomendedVacancies.length)
                this.$refs.vacancies.toggle(event);
        },
        fetchRecomendedVacancies() {
            axios.post(route('ajax.recomended_vacancies'), {city_id: this.form.city?.id, preferred_jobs: this.form.preferred_jobs})
                .then((response) => {
                    this.recomendedVacancies = response.data;
                })
                .catch((error) => {

                });
        },
        fetchVacancies(event) {
            this.vacancySelectLoading = true;

            axios
                .post('/ajax/select2', {
                    q: event.query,
                    selected: this.form.vacancy_id,
                    attribute: 'title',
                    related_model: 'App\\Models\\Vacancy',
                    resource: 'App\\Http\\Resources\\VacancyShortResource',
                })
                .then(response => {
                    this.vacancies = response.data.data;
                    this.vacancySelectLoading = false;
                });
        },
        fetchCities(event) {
            this.citySelectLoading = true;

            axios
                .post('/ajax/select2', {
                    q: event.query,
                    selected: this.form.city_id,
                    attribute: 'full_name',
                    related_model: 'App\\Models\\City',
                    resource: 'App\\Http\\Resources\\CityResource',
                })
                .then(response => {
                    this.cities = response.data.data;
                    this.citySelectLoading = false;
                });
        },
        selectRecomended(vacancy) {
            this.form.vacancy = vacancy;
            this.toggleVacancies();
        },
    },
    watch: {
        'form.preferred_jobs': function () {
            this.fetchRecomendedVacancies();
        },
        'form.city': function () {
            this.fetchRecomendedVacancies();
        }
    }
}
</script>

<style lang="scss" scoped>
.stages{
    .nav-item{
        margin-left: 5px;
    }
    .nav-item:not(.active):hover{
        cursor: pointer;
        //font-weight: 600;
        box-shadow: 0 1px 5px 1px #ddd;
        //animation: .2s ease;
        background-color: #fff;
        border-radius: 0.5rem;
    }
    .nav-item.active {
        cursor: default;
        .nav-link, .nav-link:hover{
            font-weight: 600;
            box-shadow: 0 1px 5px 1px #ddd;
            color: #fff;
        }
    }
}
</style>
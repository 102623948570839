<template>
    <Head :title="$t('create')"/>

    <Toolbar class="mb-4 !border-none">
        <template #start id="tollbar-start">
            <div class="font-medium text-xl">{{ $t('Citizenship') }}</div>
        </template>

        <template #end>
            <Button :label="$t('save')" @click.prevent="form.post(route('citizenship.store'))"></Button>
        </template>
    </Toolbar>

    <Fluid>
        <div class="flex mt-8">
            <div class="card flex flex-col gap-4 w-full">
                <div class="grid grid-flow-row md:grid-flow-col md:grid-cols-2 gap-y-7 gap-x-4">
                    <div class="gap-2 w-full">
                        <FloatLabel class="w-full">
                            <InputText id="name" v-model="form.name" :invalid="errors.name ? true : false" autocomplete="off"/>
                            <label for="name">{{ $t('citizenships.name') }}</label>
                        </FloatLabel>
                        <small class="text-red-500">{{ errors.name }}</small>
                    </div>
                    <div class="gap-2 w-full">
                        <FloatLabel class="w-full">
                            <InputText id="name" v-model="form.code" :invalid="errors.code ? true : false" autocomplete="off"/>
                            <label for="name">{{ $t('citizenships.code') }}</label>
                        </FloatLabel>
                        <small class="text-red-500">{{ errors.code }}</small>
                    </div>
                </div>
            </div>
        </div>
    </Fluid>
</template>

<script>
import { useForm, Head } from '@inertiajs/vue3'
import { usePermissions } from '../../Composables/usePermissions';

import PrimeLayout from '../../Layouts/PrimeLayout.vue';
import Alert from '../../Components/Alert.vue';
import Repeater from '../../Components/Form/Repeater.vue';
import FileInput from '../../Components/Form/FileInput.vue';

export default {
    layout: PrimeLayout,
    components: {
        Head,
        Alert,
        Repeater,
        FileInput
    },
    props: {
        errors: Object
    },
    setup() {
        const { hasRole, hasPermission } = usePermissions();

        const form = useForm({
            name: null,
            code: null
        });

        return { form, hasRole, hasPermission };
    },
}
</script>
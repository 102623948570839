<script setup>
import { useLayout } from './Sakai/composables/layout';
import { computed, ref, watch } from 'vue';
import AppFooter from './Sakai/AppFooter.vue';
import AppSidebar from './Sakai/AppSidebar.vue';
import AppTopbar from './Sakai/AppTopbar.vue';
import {usePage} from '@inertiajs/vue3';
import { useToast } from 'vue-toastification';

const { layoutConfig, layoutState, isSidebarActive, resetMenu } = useLayout();

const outsideClickListener = ref(null);

watch(isSidebarActive, (newVal) => {
    if (newVal) {
        bindOutsideClickListener();
    } else {
        unbindOutsideClickListener();
    }
});

const containerClass = computed(() => {
    return {
        'layout-overlay': layoutConfig.menuMode === 'overlay',
        'layout-static': layoutConfig.menuMode === 'static',
        'layout-static-inactive': layoutState.staticMenuDesktopInactive && layoutConfig.menuMode === 'static',
        'layout-overlay-active': layoutState.overlayMenuActive,
        'layout-mobile-active': layoutState.staticMenuMobileActive
    };
});
const bindOutsideClickListener = () => {
    if (!outsideClickListener.value) {
        outsideClickListener.value = (event) => {
            if (isOutsideClicked(event)) {
                resetMenu();
            }
        };
        document.addEventListener('click', outsideClickListener.value);
    }
};
const unbindOutsideClickListener = () => {
    if (outsideClickListener.value) {
        document.removeEventListener('click', outsideClickListener);
        outsideClickListener.value = null;
    }
};
const isOutsideClicked = (event) => {
    const sidebarEl = document.querySelector('.layout-sidebar');
    const topbarEl = document.querySelector('.layout-menu-button');

    return !(sidebarEl.isSameNode(event.target) || sidebarEl.contains(event.target) || topbarEl.isSameNode(event.target) || topbarEl.contains(event.target));
};

//Notifications
const toast = useToast();
const timeout = 2000;

watch(() => usePage().props.flash, flash => {
    let toastId = null;

    if (flash.message) {
        toastId = toast.info(flash.message, {timeout: timeout});
    }
    if (flash.success) {
        toastId = toast.success(flash.success, {timeout: timeout});
    }
    if (flash.error) {
        toastId = toast.error(flash.error, {timeout: timeout});
    }

    if (toastId !== null) {
        setTimeout(() => toast.dismiss(toastId), timeout)
    }
}, {deep: true});
</script>

<template>
    <div class="layout-wrapper" :class="containerClass">
        <app-topbar></app-topbar>
        <app-sidebar></app-sidebar>
        <div class="layout-main-container">
            <div class="layout-main">
                <div id="toolbar"></div>
                <slot/>
            </div>
            <app-footer></app-footer>
        </div>
        <div class="layout-mask animate-fadein"></div>
    </div>
    <Toast />
</template>

<!-- <script>
import AppTopbar from './Sakai/AppTopbar.vue';
import AppSidebar from './Sakai/AppSidebar.vue';
import AppFooter from './Sakai/AppFooter.vue';

export default {
    components: {
        AppTopbar,
        AppSidebar,
        AppFooter
    },
    data() {
        return {
            containerClass: 'layout-overlay'
        }
    }
}
</script> -->
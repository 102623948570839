<script setup>
import { ref } from 'vue';

import AppMenuItem from './AppMenuItem.vue';
import { Link, Head } from "@inertiajs/vue3";
import { trans } from 'laravel-vue-i18n';
import { usePermissions } from '../../Composables/usePermissions';

const { hasRole, hasPermission } = usePermissions();

const model = ref([
    {
        label: 'Home',
        items: [{ label: 'Dashboard', icon: 'pi pi-fw pi-home', to: '/' }]
    },
    {
        label: 'UI Components',
        items: [
            { label: 'Form Layout', icon: 'pi pi-fw pi-id-card', to: '/uikit/formlayout' },
            { label: 'Input', icon: 'pi pi-fw pi-check-square', to: '/uikit/input' },
            { label: 'Button', icon: 'pi pi-fw pi-mobile', to: '/uikit/button', class: 'rotated-icon' },
            { label: 'Table', icon: 'pi pi-fw pi-table', to: '/uikit/table' },
            { label: 'List', icon: 'pi pi-fw pi-list', to: '/uikit/list' },
            { label: 'Tree', icon: 'pi pi-fw pi-share-alt', to: '/uikit/tree' },
            { label: 'Panel', icon: 'pi pi-fw pi-tablet', to: '/uikit/panel' },
            { label: 'Overlay', icon: 'pi pi-fw pi-clone', to: '/uikit/overlay' },
            { label: 'Media', icon: 'pi pi-fw pi-image', to: '/uikit/media' },
            { label: 'Menu', icon: 'pi pi-fw pi-bars', to: '/uikit/menu' },
            { label: 'Message', icon: 'pi pi-fw pi-comment', to: '/uikit/message' },
            { label: 'File', icon: 'pi pi-fw pi-file', to: '/uikit/file' },
            { label: 'Chart', icon: 'pi pi-fw pi-chart-bar', to: '/uikit/charts' },
            { label: 'Timeline', icon: 'pi pi-fw pi-calendar', to: '/uikit/timeline' },
            { label: 'Misc', icon: 'pi pi-fw pi-circle', to: '/uikit/misc' }
        ]
    },
    {
        label: 'Pages',
        icon: 'pi pi-fw pi-briefcase',
        to: '/pages',
        items: [
            {
                label: 'Landing',
                icon: 'pi pi-fw pi-globe',
                to: '/landing'
            },
            {
                label: 'Auth',
                icon: 'pi pi-fw pi-user',
                items: [
                    {
                        label: 'Login',
                        icon: 'pi pi-fw pi-sign-in',
                        to: '/auth/login'
                    },
                    {
                        label: 'Error',
                        icon: 'pi pi-fw pi-times-circle',
                        to: '/auth/error'
                    },
                    {
                        label: 'Access Denied',
                        icon: 'pi pi-fw pi-lock',
                        to: '/auth/access'
                    }
                ]
            },
            {
                label: 'Crud',
                icon: 'pi pi-fw pi-pencil',
                to: '/pages/crud'
            },
            {
                label: 'Not Found',
                icon: 'pi pi-fw pi-exclamation-circle',
                to: '/pages/notfound'
            },
            {
                label: 'Empty',
                icon: 'pi pi-fw pi-circle-off',
                to: '/pages/empty'
            }
        ]
    },
    {
        label: 'Hierarchy',
        items: [
            {
                label: 'Submenu 1',
                icon: 'pi pi-fw pi-bookmark',
                items: [
                    {
                        label: 'Submenu 1.1',
                        icon: 'pi pi-fw pi-bookmark',
                        items: [
                            { label: 'Submenu 1.1.1', icon: 'pi pi-fw pi-bookmark' },
                            { label: 'Submenu 1.1.2', icon: 'pi pi-fw pi-bookmark' },
                            { label: 'Submenu 1.1.3', icon: 'pi pi-fw pi-bookmark' }
                        ]
                    },
                    {
                        label: 'Submenu 1.2',
                        icon: 'pi pi-fw pi-bookmark',
                        items: [{ label: 'Submenu 1.2.1', icon: 'pi pi-fw pi-bookmark' }]
                    }
                ]
            },
            {
                label: 'Submenu 2',
                icon: 'pi pi-fw pi-bookmark',
                items: [
                    {
                        label: 'Submenu 2.1',
                        icon: 'pi pi-fw pi-bookmark',
                        items: [
                            { label: 'Submenu 2.1.1', icon: 'pi pi-fw pi-bookmark' },
                            { label: 'Submenu 2.1.2', icon: 'pi pi-fw pi-bookmark' }
                        ]
                    },
                    {
                        label: 'Submenu 2.2',
                        icon: 'pi pi-fw pi-bookmark',
                        items: [{ label: 'Submenu 2.2.1', icon: 'pi pi-fw pi-bookmark' }]
                    }
                ]
            }
        ]
    },
    {
        label: 'Get Started',
        items: [
            {
                label: 'Documentation',
                icon: 'pi pi-fw pi-book',
                to: '/documentation'
            },
            {
                label: 'View Source',
                icon: 'pi pi-fw pi-github',
                url: 'https://github.com/primefaces/sakai-vue',
                target: '_blank'
            }
        ]
    }
]);

const menuItems = ref([{
    label: 'Меню',
    items: [
        {
            label: 'Лиды',
            icon: 'pi pi-fw pi-address-book',
            to: route('lead.index'),
            contains: '/lead',
            permission: 'show-lead'
        },
        {
            label: 'Вакансии',
            icon: 'pi pi-fw pi-briefcase',
            to: route('vacancy.index'),
            contains: '/vacancy',
            permission: 'show-vacancy'
        },
        {
            label: 'Компании',
            icon: 'pi pi-fw pi-shop',
            to: route('company.index'),
            contains: '/company',
            permission: 'show-vacancy-company'
        },
        {
            label: 'Гражданства',
            icon: 'pi pi-fw pi-globe',
            to: route('citizenship.index'),
            contains: '/citizenship',
            permission: 'show-citizenship'
        },
        {
            label: 'Обьекты',
            icon: 'pi pi-fw pi-warehouse',
            to: route('facility.index'),
            contains: '/facility',
            permission: 'show-vacancy-facility'
        },
        {
            label: 'Модерация',
            icon: 'pi pi-fw pi-eye',
            to: route('moderation.leads'),
            contains: '/moderation/leads',
            permission: 'edit-vacancy'
        },
        {
            label: 'Пользователи',
            icon: 'pi pi-fw pi-user',
            to: route('user.index'),
            contains: '/user',
            permission: 'edit-user'
        },
        /*{
            label: 'Отчет по рекрутации',
            icon: 'pi pi-fw pi-file',
            to: route('moderation.recruiters-income'),
            contains: '/moderation/recruiters-income',
            permission: 'delete-user'
        },*/
    ].filter(item => hasPermission(item.permission))
}]);

const simple = ref(false);
</script>

<template>
    <ul class="layout-menu">
        <template v-if="!simple" v-for="(item, i) in menuItems" :key="item">
            <app-menu-item v-if="!item.separator" :item="item" :index="i"></app-menu-item>
            <li v-if="item.separator" class="menu-separator"></li>
        </template>

        <template v-else>
            <li class="" v-for="item in menuItems">
                <Link v-if="item.to && !item.items && item.visible !== false" @click="itemClick($event, item, index)" :class="[item.class, { 'active-route': $page.url.startsWith('/lead') }]" tabindex="0" :href="item.to">
                    <i :class="item.icon" class="layout-menuitem-icon"></i>
                    <span class="layout-menuitem-text">{{ $t(item.label) }}</span>
                    <i class="pi pi-fw pi-angle-down layout-submenu-toggler" v-if="item.items"></i>
                </Link>
            </li>
        </template>
    </ul>
</template>

<style lang="scss" scoped></style>

<template>
    <div>

        <Head :title="$t('Daily report')" />

        <Toolbar class="mb-4 !border-none">
            <template #start id="tollbar-start">
                <div class="font-medium text-xl">{{ $t('Daily report') }}</div>
            </template>
        </Toolbar>

        <div class="card !p-0">
            <div class="flex flex-col">
                <div class="hidden md:grid grid-cols-7 gap-px border-b border-gray-300 bg-gray-200 text-center font-medium leading-6 text-gray-700">
                    <div v-for="weekDay in weekDays" class="py-2" style="background-color: var(--p-primary-color);">{{ weekDay }}</div>
                </div>
                <div class="grid grid-cols-1 md:grid-cols-7 gap-px bg-gray-200">
                    <template v-for="(item, index) in calendarMonth">
                        <div 
                            class="relative flex flex-col items-center justify-center gap-2 p0 md:py-6 h-[120px]" 
                            :class="(item.isCurrentMonth ? 'bg-white hover:cursor-pointer' : 'hidden md:flex bg-gray-50 text-gray-500')" 
                            @click="item.isCurrentMonth ? openDialog(index) : null"
                        >
                            <span class="hidden md:inline absolute top-2 left-2 rounded-full py-0.5 px-1.5" :style="item.isToday ? 'background-color: var(--p-primary-color); font-weight: 500;' : ''">{{ item.day }}</span>
                            <div class="flex flex-row w-full h-full items-center">
                                <div class="basis-1/12 md:hidden justify-items-center content-center text-center h-full" style="background-color: var(--p-primary-color);">
                                    <span class="block font-semibold">{{ item.day }}</span>
                                    <span class="block text-sm">{{ item.weekDay }}</span>
                                </div>
                                <div class="basis-11/12 md:basis-full justify-items-center">
                                    <template v-if="item.report">
                                        <div class="grid grid-cols-4 grid-rows-3 items-center text-right md:text-center gap-1 px-20 md:px-0">
                                            <div class="flex flex-row items-center">
                                                <span class="material-symbols-outlined text-sm block" translate="no">article</span>
                                                <span class="font-medium md:hidden">Обьявлений:</span>
                                            </div>
                                            <span class="text-base block">{{ item.report?.ads ?? 0 }}</span>
                                            <span class="hidden md:block">/</span>                                            
                                            <span class="md:hidden">из</span>                                                                                
                                            <span class="text-base block">{{ item.plan?.ads ?? 0 }}</span>

                                            <div class="flex flex-row items-center">
                                                <span class="material-symbols-outlined text-sm block" translate="no">call_log</span>
                                                <span class="font-medium md:hidden">Звонков:</span>
                                            </div>
                                            <span class="text-base block">{{ item.report?.calls ?? 0 }}</span>
                                            <span class="hidden md:block">/</span>                                            
                                            <span class="md:hidden">из</span>                                                                                
                                            <span class="text-base block">{{ item.plan?.calls ?? 0 }}</span>

                                            <div class="flex flex-row items-center">
                                                <span class="material-symbols-outlined text-sm block" translate="no">forum</span>
                                                <span class="font-medium md:hidden">Переписок:</span>
                                            </div>
                                            <span class="text-base block">{{ item.report?.messengers ?? 0 }}</span>
                                            <span class="hidden md:block">/</span>                                            
                                            <span class="md:hidden">из</span>                                                                                
                                            <span class="text-base block">{{ item.plan?.messengers ?? 0 }}</span>
                                        </div>
                                    </template>
                                </div>
                            </div>
                        </div>
                    </template>
                </div>
            </div>
        </div>

        <Dialog v-model:visible="dialogVisible" modal :style="{ width: '25rem' }">
            <template #header>
                <div class="flex flex-col">
                    <span class="p-dialog-title">Отчет</span>
                    <span class="text-surface-500 dark:text-surface-400 block">Введите данные для отчета</span>
                </div>
            </template>
            
            <form @submit.prevent="submitReport" class="flex flex-col gap-y-7 mt-8">
                <TextInput label="Обьявлений" v-model="form.ads" :errors="errors.ads" fluid/>
                <TextInput label="Звонков" v-model="form.calls" :errors="errors.calls" fluid/>
                <TextInput label="Переписок" v-model="form.messengers" :errors="errors.messengers" fluid/>

                <div class="flex justify-end gap-2">
                    <Button type="button" :label="$t('cancel')" :disabled="form.processing" severity="secondary" @click="dialogVisible = false"></Button>
                    <Button type="submit" :label="$t('save')" :disabled="form.processing"></Button>
                </div>
            </form>
        </Dialog>


        <!-- <Drawer v-model:visible="detailsVisible" position="right" :blockScroll="true" class="!w-full md:!w-80 lg:!w-[30rem]">
            <template #header>
                <div class="flex items-center gap-2">
                    <span class="font-bold">Введите данные для отчета</span>
                </div>
            </template>
            <div class="flex flex-col gap-y-7 mt-8">
                <TextInput label="Обьявлений" v-model="form.ads" :errors="errors.ads" fluid/>
                <TextInput label="Звонков" v-model="form.calls" :errors="errors.calls" fluid/>
                <TextInput label="Переписок" v-model="form.messengers" :errors="errors.messengers" fluid/>
            </div>
            <Comments v-if="!mobile" v-model="form.comments" class="max-h-[60vh] !h-[60vh]"></Comments>
            <template #footer>
                <div class="flex items-center gap-2">
                    <Button :label="$t('save')" class="flex-auto"></Button>
                    <Button :label="$t('cancel')" class="flex-auto" severity="secondary" outlined></Button>
                </div>
            </template>
        </Drawer> -->

    </div>
</template>

<script>
import PrimeLayout from "../../Layouts/PrimeLayout.vue";
import { ref } from 'vue';
import { Head, useForm, Link, router, usePage } from "@inertiajs/vue3"
import { usePermissions } from '../../Composables/usePermissions';
import { TextInput } from "../../Components/PrimeForm";
//import Comments from '../Lead/Comments.vue';
import moment from "moment";

export default {
    layout: PrimeLayout,
    components: {
        Head,
        TextInput,
        //Comments
    },
    props: {
        //user: Object,
        reported: Object,
        records: Array,
        errors: Object,
        plan: Object
    },
    setup(props) {
        const params = route().params;
        let dates;

        if (params.from && params.to) {
            dates = [moment(params.from, 'YYYY-MM-DD'), moment(params.to, 'YYYY-MM-DD')];
        }
        else {
            dates = [moment().startOf('isoWeek'), moment().endOf('isoWeek')];
        }

        const reported = props.reported;
        const user = usePage().props.auth.user;

        const weekDays = ['Пн', 'Вт', 'Ср', 'Чт', 'Пт', 'Сб', 'Вс'];

        // const form = useForm({
        //     ads: reported?.ads ?? Array(7),
        //     calls: reported?.calls ?? Array(7),
        //     messengers: reported?.messengers ?? Array(7),
        //     from: dates[0].format('YYYY-MM-DD'),
        //     to: dates[1].format('YYYY-MM-DD')
        // });
        
        const week = ref(dates);
        const { hasRole, hasPermission } = usePermissions();

        return { hasRole, hasPermission, week, user, weekDays };
    },
    data() {
        return {
            form: null,
            calendarMonth: [],
            dialogVisible: false,
            detailsVisible: false
        };
    },
    methods: {
        submitReport() {

            this.form.post(route('daily-report.store'), {
                preserveScroll: true,
                preserveState: false,
                onSuccess: () => {
                    this.form = null;
                    this.dialogVisible = false;
                }
            });
        },
        toggleActive(index) {
            console.log('click');
            this.calendarMonth[index].active = !this.calendarMonth[index].active;
        },
        openDialog(index) {
            const calendarItem = this.calendarMonth[index];
            const report = calendarItem?.report;

            this.form = useForm({
                user_id: this.user.id,
                ads: report?.ads ?? null,
                calls: report?.calls ?? null,
                messengers: report?.messengers ?? null,
                date: calendarItem.date
            });

            this.dialogVisible = true;
        },
        showDetails(index) {
            this.detailsVisible = true;
        },
        getCalendarMonth(month, year) {
            const adjustedMonth = month - 1;
            const firstDayOfMonth = moment([year, adjustedMonth]);
            const startDate = moment(firstDayOfMonth).startOf('isoWeek');
            const endDate = moment(startDate).add(6, 'weeks').subtract(1, 'day');

            
            const calendarDays = [];
            const currentDate = moment(startDate);
            let weekDayIndex = 0;
            while (currentDate.isSameOrBefore(endDate)) {
                const report = this.records.find(item => item.date == currentDate.format('DD.MM.YYYY'));
                const weekDay = this.weekDays[weekDayIndex];

                calendarDays.push({
                    day: currentDate.date(),
                    weekDay: weekDay,
                    date: currentDate.format('DD.MM.YYYY'),
                    isCurrentMonth: currentDate.month() === adjustedMonth,
                    isToday: currentDate.diff(moment().startOf('day'), 'days') == 0,
                    plan: null,
                    report: report ?? null,
                });

                weekDayIndex = (weekDayIndex == 6) ? 0 : (weekDayIndex + 1);
                currentDate.add(1, 'days');
            }

            return calendarDays;
        }

    },
    created() {
        this.calendarMonth = this.getCalendarMonth(10, 2024);
    },
    watch: {
        week(value) {
            if (value) {
                router.get('daily-report', {
                    from: moment(value[0]).format('YYYY-MM-DD'),
                    to: moment(value[1]).format('YYYY-MM-DD'),
                });
            }
        }
    }
}

</script>
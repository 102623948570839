<template>
    <Head :title="$t('Leads')"/>
    <Listnav :title="$t('Leads')">
        <template #middle>
            <div class="col-xl-9 col-lg-9 col-md-9 my-sm-auto ms-sm-auto me-sm-0 mx-auto mt-3">
                <!-- <a v-for="vacancy in hot_vacancies" href="javascript.void(0);" @click.prevent="$refs.vacancyShow.open(vacancy)" class="btn btn-default btn-outline-primary custom-lead-bage">
                    <span>{{ vacancy.title }}</span>
                    <span class="badge badge-secondary">{{ vacancy.number_of_workplaces }}</span>
                </a> -->
                <div class="row">
                    <div class="form-group m-0">
                        <div class="input-group w-50 mx-auto">
                            <span class="input-group-text text-body"><i class="fas fa-search" aria-hidden="true"></i></span>
                            <input type="text" class="form-control border-right-gray" placeholder="Поиск" v-model="search">
                        </div>
                    </div>
                </div>
            </div>
        </template>
        <template #buttons>
            <div v-if="hasPermission('create-lead')" class="col-auto my-auto d-flex flex-column">
                <div class="display-flex flex-row">
                    <template v-if="hasRole('Manager') || hasRole('Admin')">
                        <a :href="route('lead.export')" class="btn btn-info btn-sm ms-auto m-0 me-2 p-2" style="width: 34px;" v-tooltip="$t('export_excel')">
                            <span class="btn-inner--icon"><i class="far fa-file-excel"></i></span>
                        </a>
                    </template>
                    <Link :href="route('lead.create')" class="btn btn-primary btn-sm ms-auto m-0">{{ $t('create') }}</Link>
                </div>
            </div>
        </template>
    </Listnav>
    <div class="pt-4">
        <div id="kanban">
            <div class="kb-main">
                <perfect-scrollbar id="kb_main_container" class="kb" :options="scrollOptions.wrapper" ref="container">
                    <div class="kb-column" v-for="column,cid in columns">
                        <div class="kb-column-header">
                            <div class="kb-column-title">
                                <span class="badge badge-pill badge-lg" :class="column.background">{{ column.title }}</span>
                            </div>
                        </div>
                        <perfect-scrollbar class="kb-column-body" @ps-y-reach-end="onScroll($event, column.pid)" :options="scrollOptions.column" :ref="column.pid">
                            <draggable
                            :id="column.sid"
                            :data-pid="column.pid"
                            :key="column.pid"
                            :list="column.items"
                            fallback-class="kb-dragging" 
                            :force-fallback="false"
                            itemKey="id"
                            v-bind="dragOptions"
                            @start="dragStart"
                            @end="dragEnd"
                            class="kb-draggable"
                            draggable=".kb-item:not(.disable-drag)"
                            @touchstart="dragOptions.disabled = true"
                            >
                                <template #item="{ element, index }">
                                    <div class="kb-item card shadow-none" :id="element.id" :class="element.returned ? 'returned' : ''" @mouseover="checkClass">
                                        <div class="card-body">
                                            <div class="d-flex">
                                                <div>
                                                    <Link :href="route('lead.edit', element.id)" class="kb-title card-title h5 d-block text-darker disable-drag">
                                                        {{ element.full_name }}
                                                    </Link>
                                                </div>
                                                <div class="ms-auto">
                                                    <span v-show="element?.age" class="age">{{ element?.age }}</span>
                                                    <i class="fas" :class="sexIcons[element.sex]"></i>
                                                </div>
                                            </div>
                                            <p class="card-description font-weight-bold text-truncate mb-0" style="color: #344767;" v-if="element?.city?.name"><i class="fas fa-map-marker-alt"></i> {{ element?.city?.name }}</p>
                                            <p class="card-description text-truncate" v-if="element.vacancy?.title"><i class="fas fa-briefcase"></i> {{ element.vacancy?.title }}</p>
                                            <div class="d-flex flex-wrap">
                                                <a class="kb-phone disable-drag" @click.prevent="copyPhone($event, record.phone)" data-bs-toggle="tooltip" data-bs-original-title="Скопировано" v-for="record,num in element.phones_json" :key="record.id" :href="'tel:'+record.phone"><i class="fas fa-phone"></i>{{ record.phone }}</a>
                                            </div>
                                            <div class="d-flex mt-2">
                                                <div class="me-2" v-if="element.file_count > 0"> 
                                                    <i class="fa fa-paperclip me-1 text-sm" aria-hidden="true"></i>
                                                    <span class="text-sm">{{ element.file_count }}</span>
                                                </div>
                                                <div>
                                                    <div>
                                                        <i class="fas fa-calendar-alt text-sm me-1"></i>
                                                        <span class="text-xs">{{ element?.created_date }}</span>
                                                    </div>
                                                    <div v-if="element?.commented_at">
                                                        <i class="fas fa-comment text-xs me-1"></i>
                                                        <span class="text-xs">{{ element?.commented_at }}</span>
                                                    </div>
                                                </div>
                                                <div v-show="element?.responsible" class="avatar-group ms-auto">
                                                    
                                                    <a href="javascript:;" class="avatar avatar-xs rounded-circle disable-drag" data-bs-toggle="tooltip" :data-bs-original-title="element.responsible?.firstname+' '+element.responsible?.lastname">
                                                        <img alt="Image placeholder" :src="element.responsible?.avatar" class="">
                                                    </a>
                                                    <span class="responsible-text">{{ element.responsible?.lastname }}</span>
                                                    <!-- <a href="javascript:;" class="avatar avatar-xs me-2 rounded-circle" data-toggle="tooltip" data-original-title="Jessica Rowland">
                                                        <img alt="Image placeholder" src="/img/team-2.jpg" class="">
                                                    </a> -->
                                                </div>
                                            </div>
                                            <div class="errors-block" v-if="element.errors">
                                                <span class="error-icon">
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-exclamation-circle" viewBox="0 0 16 16">
                                                        <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14m0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16"/>
                                                        <path d="M7.002 11a1 1 0 1 1 2 0 1 1 0 0 1-2 0M7.1 4.995a.905.905 0 1 1 1.8 0l-.35 3.507a.552.552 0 0 1-1.1 0z"/>
                                                    </svg>
                                                </span>
                                                <div class="errors-text">
                                                    <span v-for="error in element.errors">
                                                        {{ error }}
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </template>
                            </draggable>
                        </perfect-scrollbar>
                        <div class="kb-column-footer"></div>
                    </div>
                </perfect-scrollbar>
                <scroll-arrows :perfect-scrollbar="ps"/>
            </div>
            <!-- <vacancy-show ref="vacancyShow"></vacancy-show> -->
        </div>
    </div>
</template>

<script>
import { Link, Head, usePage } from "@inertiajs/vue3";
import { usePermissions } from '../../Composables/usePermissions';
import draggable from "vuedraggable";
import { PerfectScrollbar } from 'vue3-perfect-scrollbar'
import ScrollArrows from "../../Components/ScrollArrows.vue";
import AuthenticatedLayout from "../../Layouts/AuthenticatedLayout.vue";
import PrimeLayout from "../../Layouts/PrimeLayout.vue";
import Listnav from "../../Layouts/Navbars/Auth/Listnav.vue";
import VacancyShow from "./VacancyShow.vue";
import Alert from '../../Components/Alert.vue';
import { useToast } from "vue-toastification";
import moment from "moment";

export default {
    layout: PrimeLayout,
    components: {
        draggable,
        PerfectScrollbar,
        ScrollArrows,
        Listnav,
        Head,
        Link,
        VacancyShow,
        Alert
    },
    setup() {
        const { hasRole, hasPermission } = usePermissions();
        return {hasRole, hasPermission};
    },
    props: {
        hot_vacancies: Object
    },
    data() {
        return {
            kbItems: route('lead.kanban.items'),
            kbAction: route('lead.kanban.action'),
            cantScrollLeft: false,
            cantScrollRight: false,
            ps: null,
            user: usePage().props.auth.user,
            dragOptions: {
                //animation: 200,
                group: "leads",
                disabled: false,
                ghostClass: "kb-moving",
                //chosenClass: "kb-chosen",
                //dragClass: "kb-dragging"
            },
            sexIcons: ['','fa-mars','fa-venus','fa-venus-mars'],
            scrollOptions: {
                wrapper:{
                    suppressScrollY: true,
                    handlers: ['drag-thumb', 'keyboard', 'touch', 'wheel']
                },
                column: {
                    suppressScrollX: true,
                    handlers: ['drag-thumb', 'keyboard', 'wheel', 'touch'],
                    maxScrollbarLength: 300
                }
            },
            columns: {},
            search: ''
        }
    },
    methods: {
        dragStart(event) {
            const className = 'grabbing';
            const html = document.getElementsByTagName('html').item(0);
            if (html && new RegExp(className).test(html.className) === false) {
                html.className += ' ' + className; // use a space in case there are other classNames
            }
        },
        dragEnd(event) {
            this.changeStage(event);
            const className = 'grabbing';
            const html = document.getElementsByTagName('html').item(0);
            if (html && new RegExp(className).test(html.className) === true) {
                // Remove className with the added space (from setClassToHTMLElement)
                html.className = html.className.replace(
                    new RegExp(' ' + className),
                    ''
                );
                // Remove className without added space (just in case)
                html.className = html.className.replace(new RegExp(className), '');
            }
        },
        changeStage(event) {
            const newStage = event.to.getAttribute('data-pid');
            const newIndex = event.newIndex;
            if (event.type == 'end') {
                const ItemId = event.item.id;
                const StageId = event.to.id;
                axios.post(this.kbAction, {
                    action: 'stage',
                    id: ItemId,
                    stage_id: StageId
                }).then(response => {
                    this.columns[newStage].items[newIndex].stage_id = parseInt(event.to.id);
                }).catch(error => {
                    const response = error.response.data;
                    if (response?.errors) {
                        this.columns[newStage].items[newIndex].errors = response.errors;
                        //const targetStage = event.to.getAttribute('data-pid');
                        //this.openValidationForm(item, targetStage, response.errors);
                    }
                    const item = this.cancelMove(event);
                    //this.openEditForm(Item);
                });
            }
        },
        firstLetter(item) {
            const responsible = _.cloneDeep(item.responsible);
            const first = responsible.firstname.charAt(0).toUpperCase();
            const last = responsible.lastname.charAt(0).toUpperCase();
            return last;
        },
        cancelMove(event) {
            const oldStage = event.from.getAttribute('data-pid');
            const newStage = event.to.getAttribute('data-pid');
            const newIndex = event.newIndex;
            const oldIndex = event.oldIndex;
            let item = this.columns[newStage].items[newIndex];
            item.returned = true;
            this.columns[newStage].items.splice(newIndex, 1);
            this.columns[oldStage].items = [
                ...this.columns[oldStage].items.slice(0, oldIndex),
                item,
                ...this.columns[oldStage].items.slice(oldIndex)
            ];
            setTimeout(() => {
                if (typeof this.columns[oldStage].items[oldIndex] !== 'undefined')
                    this.columns[oldStage].items[oldIndex].returned = false
            }, 3000);
            return item;
        },
        onScroll(event, pid) {
            let url = this.columns[pid].next_page_url;
            if (url) {
                axios.get(url).then(response => {
                    let res = response.data;
                    this.columns[pid].next_page_url = res.next_page_url;
                    res.items.forEach(element => {
                        this.columns[pid].items.push(element);
                    });
                    if (this.$refs[pid].$el)
                        this.$refs[pid].$el.update();
                });
            }
        },
        initColumns() {
            axios
                .get(this.kbItems)
                .then(response => {
                    this.columns = response.data;
                    this.currentItem = this.columns.stage0.items[0];
                    //console.log(this.columns);
                    this.$nextTick(() => {
                        $('.kb-main').find('[data-bs-toggle="tooltip"]:not(.kb-phone)').tooltip();
                        $('.kb-main').find('[data-toggle="tooltip"]').tooltip({
                            trigger: 'manual'
                        });
                    });
                });
        },
        scrollRight() {
            this.$refs.container.$el.scrollBy(100, 0);
        },
        scrollLeft() {
            this.$refs.container.$el.scrollBy(-100, 0);
        },
        //Prettify
        copyPhone(event, phone) {
            const target = event.currentTarget;
            
            navigator.clipboard.writeText(phone)
            .then(() => {
                $(target).tooltip('show');
                $(target).tooltip('disable');
            })
            .catch(err => {
                // Error occurred while copying to clipboard
                console.error('Failed to copy to clipboard:', err);
            })
            .finally(() => {

            });
        },
        checkClass(event) {
            const parent = event.target.closest('.kb-item');
            if (event.target.classList.contains('disable-drag')) {
                parent.classList.add('disable-drag');
            } else {
                parent.classList.remove('disable-drag');
            }
        },
        //Deprecated
        openValidationForm(item, targetStage, errors) {
            const stage_id = parseInt(targetStage.replace('stage', ''));
            //item = _.cloneDeep(item);
            //item.stage_id = stage_id;
            //this.errors = errors;
            //this.columns[item.stage_id].items[index].errors = errors;
        },
        setHeight() {
            const scrollHeight = 15;
            let offset = document.getElementById("kanban").offsetTop;
            let height = window.innerHeight - offset - scrollHeight;
            document.getElementById('kb_main_container').style.height = height + "px"
        },
        findItemById(id) {
            let result = false;
            Object.keys(this.columns).some((stage) => { 
                const index = this.columns[stage].items.findIndex(item => item.id === id);
                if (index > -1) {
                    result = {
                        index: index,
                        stage: stage,
                        item: this.columns[stage].items[index]
                    };
                    return true;
                }
                return false;
            });

            return result;
        },
        //From events
        newItem(item) {
            this.columns['stage'+item.stage_id].items.unshift(item);
        },
        checkResponsible(lead) {
            if (this.hasRole('Manager') || this.hasRole('Admin'))
                return true;
            if (lead.stage_id == 0)
                return true;
            if (!this.user)
                return false;
            if (!lead.responsible)
                return false;
            return (this.user?.id == lead.responsible?.id);
        },
        editItem(item) {
            const originalItem = this.findItemById(item.id);
            if (originalItem) {
                const new_stage_id = item.stage_id;
                //для рекрутера стадии не меняются
                if (new_stage_id == 4 && this.hasRole('Recruiter'))
                    return;
                this.columns[originalItem.stage].items.splice(originalItem.index, 1);
                if (this.checkResponsible(item))
                    this.columns['stage' + new_stage_id].items.unshift(item);
            }
            else {
                this.newItem(item);
            }
        },
    },
    mounted() {
        this.initColumns();
        this.ps = this.$refs.container.$el;
        //Autoheight
        window.onload = this.setHeight;
        window.onresize = this.setHeight;
        this.setHeight();
        const toast = useToast();
        if (this.$page.props.flash.success) {
            toast.success(this.$page.props.flash.success, {
                timeout: 2000
            });
        }
    },
    created() {
        Echo.private('App.Models.Lead')
            .listen('.LeadCreated', (e) => {
                const lead = e.model;
                if (this.checkResponsible(lead))
                    this.newItem(lead);
            })
            .listen('.LeadUpdated', (e) => {
                const lead = e.model;
                this.editItem(lead);
            });
    },
    destroyed() {
        Echo.leave('App.Models.Lead');
    },
    computed: {
        stages: function () {
            let stages = {};
            for (let key in this.columns) {
                if (this.columns.hasOwnProperty(key)) {
                    stages[key] = {
                        title: this.columns[key].title,
                        background: this.columns[key].background,
                        pid: this.columns[key].pid,
                        sid: this.columns[key].sid,
                    }
                }
            }
            return stages;
        }
    },
    watch: {
        search(newVal) {
            if (newVal.length > 0) {
                axios
                    .get(route('lead.kanban.items', { q: newVal }))
                    .then(response => {
                        this.columns = response.data;
                        this.currentItem = this.columns.stage0.items[0]
                        this.$nextTick(() => {
                            $('.kb-main').find('[data-bs-toggle="tooltip"]:not(.kb-phone)').tooltip();
                            $('.kb-main').find('[data-toggle="tooltip"]').tooltip({
                                trigger: 'manual'
                            });
                        });
                    });
            }
            else {
                this.initColumns();
            }
        }
    }
}
</script>

<style lang="scss">
    $kb-width: 350px;
    $kb-column-title-fs: 0.9rem;
    $kb-card-title-fs: 1.05rem;
    $kb-card-text-fs: $kb-card-title-fs - 0.25rem;
    $kb-card-phone-fs: $kb-card-title-fs - 0.15rem;
    $kb-card-padding: 0.6rem;

    #kanban{
        margin-left: 20px;
        margin-right: 20px;

        .ps .ps__rail-x:hover, .ps .ps__rail-y:hover, .ps .ps__rail-x:focus, .ps .ps__rail-y:focus, .ps .ps__rail-x.ps--clicking, .ps .ps__rail-y.ps--clicking{
            background-color: transparent;
        }

        .kb-main{
            position: relative;

            .returned {
                animation: herror 2s 1;
            }

            @keyframes herror {
                50% {
                    background: rgba($color: #fd5c70, $alpha: 0.5);
                }
            }
        }
    }
    .kb{
        display: flex;
        flex: 1 1 auto;
        overflow: auto;
        position: relative;
        max-height: 86vh;
        padding-bottom: 15px;

        .kb-draggable{
            //min-height: 70vh;
            height: 100%;
        }

        .kb-column{
            display: flex;
            flex-direction: column;
            border-radius: 0.75rem;
            background-color: #e9ecef;
            margin-left: 10px;
            margin-right: 10px;
            min-width: calc($kb-width + 20px);

            &:first-child{
                margin-left: 0;
            }

            &:last-child{
                margin-right: 0;
            }

            .kb-column-header{
                padding: 10px;

                .kb-column-title{
                    font-weight: 600;
                    margin: 0;
                    padding: 0;
                    display: block;
                    color: #344767;

                    .badge{
                        width: 100%;
                        font-size: $kb-column-title-fs;
                    }
                }
            }

            .kb-column-body{
                display: flex;
                flex-direction: column;
                position: relative;
                flex: 1;
                overflow-y: auto;
                margin: 0;
                padding: 5px 10px 0;
                list-style: none;
            }

            .kb-item{
                width: $kb-width;
                margin-bottom: 0.5rem;
                margin-top: 0.5rem;

                .card-title{
                    font-size: $kb-card-title-fs;
                }

                .card-body{
                    padding: $kb-card-padding;
                }

                p{
                    font-size: $kb-card-text-fs;
                    margin-bottom: 0.5rem;
                }

                .kb-phone{
                    display: block;
                    width: 50%;
                    font-size: $kb-card-phone-fs;
                    .fas.fa-phone{
                        margin-right: 10px;
                    }
                }

                .avatar-group{
                    line-height: 1rem;
                }

                &:first-child{
                    margin-top: 0;
                }

                .fa-mars{
                    color: #0dcaf0;
                    font-size: 1.2rem;
                    position: relative;
                    top: -2px;
                }
                .fa-venus{
                    color: #d63384;
                    font-size: 1.2rem;
                    position: relative;
                    top: 2px;
                }
                .fa-venus-mars{
                    color: #596CFF;
                    font-size: 1.2rem;
                    position: relative;
                    top: 1px;
                }
                .age{
                    background-color: #e4e8ed;
                    border-radius: 50%;
                    font-size: 0.7rem;
                    color: #5974a2;
                    margin-right: 5px;
                    width: 20px;
                    height: 20px;
                    display: inline-block;
                    line-height: 20px;
                    text-align: center;
                    position: relative;
                    top: -4px;
                }

                .responsible-text{
                    line-height: 24px;
                    vertical-align: bottom;
                    font-size: 0.9rem;
                }

                .errors-block{
                    color: red;
                    display: flex;
                    align-items: center;
                    .error-icon{
                        color: red;
                    }
                    .errors-text{
                        font-size: 0.7rem;
                        margin-left: 10px;
                        span{
                            display: block;
                            margin-top: 2px;
                        }
                    }
                }
            }
        }
    }

    @media (-webkit-min-device-pixel-ratio: 1.25) {
        .kb{
            .kb-column{
                min-width: calc($kb-width - 30px);
                margin-left: 5px;
                margin-right: 5px;

                .kb-column-header{
                    .kb-column-title{
                        .badge{
                            font-size: $kb-column-title-fs - 0.1rem;
                        }
                    }
                }

                .kb-item{
                    width: $kb-width - 50px;

                    .card-title{
                        font-size: $kb-card-title-fs - 0.1rem;
                    }

                    .card-body{
                        padding: $kb-card-padding - 0.1rem;
                    }

                    p{
                        font-size: $kb-card-text-fs - 0.1rem;
                    }

                    .kb-phone{
                        font-size: $kb-card-phone-fs - 0.1rem;
                    }
                }
            }
        }
    }

    .kb-moving{
        opacity: 0.25;
        // cursor: move;
    }

    // .kb-chosen{
    //     //opacity: 1;
    //     //background-color: green;
    // }

    .kb-dragging{
        opacity: 0.9;
        transform: rotate(3deg);
    }

    .grabbing * {
        cursor: move !important;
        cursor: -webkit-grabbing !important;
        cursor:    -moz-grabbing !important;
        cursor:         grabbing !important; 
    }

    .custom-lead-bage{
        font-size: 11px;
        margin-bottom: 0.5rem;
        padding: 10px;
        color: #495057;
        border-color: #d2d6da;
        margin-left: 5px;
    }
</style>
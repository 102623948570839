<template>
    <div :class="class">
        <div class="flex flex-row justify-between" v-for="(item, key) in modelValue">
            <div class="flex flex-wrap md:flex-nowrap gap-4 basis-11/12 pe-2 2xl:pe-0">
                <slot :item="item" :index="key" :repeater-update="handleUpdate" :error="getError(key)" />
            </div>
            <div class="flex basis-1/12 justify-end items-start">
                <Button 
                    class="w-full"
                    icon="pi pi-minus" 
                    v-if="key<(this.modelValue.length-1)" 
                    :disabled="(this.modelValue.length == 1)" 
                    @click.prevent="removeField(key)" 
                    severity="danger" 
                    outlined
                />
                <Button 
                    class="w-full"
                    icon="pi pi-plus" 
                    v-if="key==(this.modelValue.length-1)" 
                    @click.prevent="addField()"
                    severity="success" 
                    outlined 
                />
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        name: {
            type: String,
            required: true
        },
        modelValue: {
            type: Array,
            required: true
        },
        label: {
            type: String,
            required: false,
            default: null
        },
        errors: {
            type: Object,
            required: false,
            default: null
        },
        wrapper: {
            type: String,
            default: null
        },
        class: {
            type: String,
            default: 'row'
        }
    },
    methods: {
        addField() {
            this.modelValue.push({id: null});
        },
        removeField(key) {
            this.modelValue.splice(key, 1);
        },
        handleUpdate(index, name, value) {
            let items = _.cloneDeep(this.modelValue);
            items[index][name] = value;
            this.$emit('update:modelValue', items);
        },
        getError(index) {
            let errors = {};
            const rowKey = this.name + '.' + index + '.';
            Object.keys(this.errors).forEach((key) => {
                if (key.startsWith(rowKey)) {
                    const shortKey = key.replace(rowKey, '');
                    errors[shortKey] = this.errors[key];
                }
            });
            return errors;
        }
    },
    mounted() {
        if (this.modelValue.length == 0) {
            this.addField();
        }
    },
}
</script>

<style lang="scss" type="scoped">
    .repeater-group{
        position: relative;
        display: flex;
        flex-wrap: wrap;
        align-items: stretch;
        width: 100%;

        .form-group{
            margin-right: 1rem;
            flex: 1;
        }

        .repeater-buttons{
            display: flex;
            align-items: end;
            flex-direction: column-reverse;
            margin-bottom: 1rem;

            button.btn.btn-success, button.btn.btn-danger{
                margin-bottom: 0;
            }
        }
    }
</style>
<template>
    <div :class="class">
        <FloatLabel class="w-full">
            <InputText v-model="value" :id="id" :type="type" :name="name" :readonly="readonly" :invalid="errors ? true : false" autocomplete="off" :fluid="fluid"/>
            <label v-if="label" :for="id">{{ label }}</label>
        </FloatLabel>
        <small class="text-red-500">{{ errors }}</small>
    </div>
</template>


<script>
import { nanoid } from 'nanoid';

export default {
    props: {
        label: {
            type: String,
            required: false,
            default: null
        },
        modelValue: {
            type: [String, Number, null],
            required: true
        },
        name: {
            type: String,
            required: false,
            default: null
        },
        class: {
            type: String,
            required: false,
            default: 'gap-2 w-full'
        },
        readonly: {
            type: Boolean,
            required: false,
            default: false
        },
        type: {
            type: String,
            required: false,
            default: 'text'
        },
        errors: {
            type: [Array, String],
            required: false,
            default: null
        },
        fluid: {
            type: [Boolean, null],
            required: false,
            default: null
        }
    },
    data() {
        return {};
    },
    computed: {
        value: {
            get: function () {
                return this.modelValue;
            },
            set: function (newValue) {
                this.$emit('update:modelValue', newValue);
            },
        },
        id() {
            const id = nanoid(5);
            if (this.name)
                return this.name + "-" + id;
            return id;
        },
    },
}
</script>
<template>
    <div :class="class">
        <InputGroup class="!items-start">
            <InputGroupAddon class="!p-0 items-center self-stretch" :class="invalid ? '!border-red-400' : null">
                <Select class="!border-none items-center self-stretch" v-model="selectedCode" :options="codes" optionLabel="name" @change="onCodeChange" pt:dropdown:class="!hidden">
                    <template #value="slotProps">
                        <div v-if="slotProps.value" class="flex items-center">
                            <img :alt="slotProps.value.label" src="https://primefaces.org/cdn/primevue/images/flag/flag_placeholder.png" :class="`flag flag-${slotProps.value.code.toLowerCase()}`" style="width: 18px" />
                            <!-- <div>{{ slotProps.value.name }}</div> -->
                        </div>
                    </template>
                    <template #option="slotProps">
                        <div class="flex items-center">
                            <img :alt="slotProps.option.label" src="https://primefaces.org/cdn/primevue/images/flag/flag_placeholder.png" :class="`mr-2 flag flag-${slotProps.option.code.toLowerCase()}`" style="width: 18px" />
                            <div>{{ slotProps.option.name }}</div>
                        </div>
                    </template>
                </Select>
            </InputGroupAddon>
            <FloatLabel>
                <InputText fluid v-model="value" @keydown.exact="onKeyDown" @paste="onPaste" :id="id" :invalid="invalid"/>
                <label :for="id">{{ label }}</label>
            </FloatLabel>
        </InputGroup>
        <small class="text-red-500">{{ errors }}</small>
    </div>
</template>


<script>
import { nanoid } from 'nanoid'

export default {
    props: {
        modelValue: [String, null],
        label: {
            type: String,
            required: false,
            default: null
        },
        label: {
            type: String,
            required: false,
            default: null
        },
        class: {
            type: String,
            required: false,
            default: 'gap-2 w-full',
        },
        name: {
            type: String,
            required: false,
            default: null
        },
        errors: {
            type: [Array, String],
            required: false,
            default: null
        }
    },
    data() {
        return {
            selectedCode: null,
            length: 12,
            codes: [
                { name: '380', code: 'UA', length: 12},
                { name: '48', code: 'PL', length: 11 },
            ],
            context: false
        };
    },
    methods: {
        onKeyDown(event) {
            const alwaysAllowed = ['Backspace', 'Delete', 'ArrowLeft', 'ArrowRight', 'Tab', 'Control'];

            if (!alwaysAllowed.includes(event.key)) {
                const allowedKeys = [
                    '+', '-', ' ', '(', ')',
                    ...Array.from({ length: 10 }, (_, i) => `${i}`),
                ];

                if (!allowedKeys.includes(event.key) || this.value?.length >= this.length) {
                    event.preventDefault();
                }
            }
        },
        onPaste(event) {
            event.preventDefault();
            const value = event.clipboardData.getData('text');
            const sanitizedValue = value.replace(/[^\d\s-+()]/g, '');
            this.value = sanitizedValue;
        },
        onCodeChange() {
            this.value = this.selectedCode.name;
            this.length = this.selectedCode.length;        
        },
        checkCode(val) {
            let found = false;
            if (val) {
                this.codes.forEach((code) => {
                    if (val.startsWith(code.name) || val.startsWith('+'+code.name)) {
                        this.selectedCode = code;
                        this.length = code.length;
                        found = true;
                        return;
                    }
                });
            }
            
            return found;
        }
    },
    computed: {
        id() {
            const id = nanoid(5);
            if (this.name)
                return this.name + "-" + id;
            return id;
        },
        invalid() {
            return this.errors ? true : false;
        },
        value: {
            get() {
                return this.modelValue;
            },
            set(val, oldVal) {
                if (val == oldVal)
                    return;

                if(!this.checkCode(val))
                    this.selectedCode = null;
                
                this.$emit('update:modelValue', val);
            }
        }
    },
    mounted() {
        this.checkCode(this.value);
    }
}
</script>

<style lang="scss" scoped>
    .p-floatlabel:has(input:focus), 
    .p-floatlabel:has(input.p-filled), 
    .p-floatlabel:has(input:-webkit-autofill), 
    .p-floatlabel:has(textarea:focus), 
    .p-floatlabel:has(textarea.p-filled), 
    .p-floatlabel:has(.p-inputwrapper-focus), 
    .p-floatlabel:has(.p-inputwrapper-filled) {
        label{
            left: -2rem;
        }
    }


    .p-inputgroup:has(input:focus) .p-inputgroupaddon{
        border-color: var(--p-inputtext-focus-border-color) !important;
    }
</style>
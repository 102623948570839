<template>
    <div class="card border flex flex-col !p-0 !mb-0" :class="!vacancy.active ? '!opacity-50' : ''">
        <div class="card-body flex flex-col !p-4">
            <div class="card-title flex flex-col gap-2">
                <div class="flex flex-row items-start gap-1 justify-between">
                    <div class="text-xl font-medium md:truncate md:hover:overflow-visible md:hover:whitespace-normal">
                        <span v-if="vacancy.hot" class="material-symbols-outlined text-rose-400 block float-left mt-[-2px]" translate="no">mode_heat</span>
                        {{ vacancy.title }}
                    </div>
                    <Badge :value="vacancy.number_of_workplaces" size="large" severity="primary"></Badge>
                </div>
                <div class="flex flex-row justify-between gap-2 flex-wrap">
                    <div class="flex flex-col">
                        <div class="flex items-center gap-1">
                            <span class="material-symbols-outlined text-base" translate="no">location_on</span>
                            <div class="text-gray-500 font-normal">{{ vacancy.work_city }}</div>
                        </div>
                        <div class="flex items-center gap-1" v-if="vacancy.closest_city">
                            <span class="material-symbols-outlined text-base" translate="no">mode_of_travel</span>
                            <div class="text-gray-500 font-normal">{{ vacancy.closest_city }}</div>
                            <p class="text-gray-500" v-if="vacancy.closest_city_km">{{ vacancy.closest_city_km }}<small
                                    class="ms-px">km</small></p>
                        </div>
                    </div>
                    <div class="flex flex-row gap-2">
                        <div class="self-center" v-for="citizenship in vacancy.citizenships" v-tooltip.top="citizenship?.name">
                            <img src="/img/flag_placeholder.png" :class="`flag flag-${citizenship?.code}`"
                                style="width: 22px;">
                        </div>
                    </div>
                </div>
            </div>
            <divider class="!mt-2"></divider>
            <div class="card-content flex flex-col">
                <div class="flex flex-row justify-between items-center flex-wrap gap-2">
                    <div class="flex flex-row gap-2">
                        <Tag v-if="vacancy.base_rate" severity="secondary" class="!bg-gray-800 !text-[var(--p-button-primary-color)]">
                            <div class="flex items-center gap-1 px-1">
                                <span class="material-symbols-outlined text-sm" translate="no">payments</span>
                                <span class="text-sm">{{ vacancy.base_rate }}</span>
                            </div>
                        </Tag>
                        <Tag v-if="vacancy.young_rate" severity="secondary" class="!bg-gray-800 !text-[var(--p-button-primary-color)]">
                            <div class="flex items-center gap-1 px-1">
                                <span class="material-symbols-outlined text-sm" translate="no">child_care</span>
                                <span class="text-sm">{{ vacancy.young_rate }}</span>
                            </div>
                        </Tag>
                        <Tag v-if="vacancy.student_rate" severity="secondary" class="!bg-gray-800 !text-[var(--p-button-primary-color)]">
                            <div class="flex items-center gap-1 px-1">
                                <span class="material-symbols-outlined text-sm" translate="no">school</span>
                                <span class="text-sm">{{ vacancy.student_rate }}</span>
                            </div>
                        </Tag>
                    </div>
                    <div class="flex flex-row gap-1">
                        <span v-if="vacancy.udt" class="material-symbols-outlined text-violet-600"
                            v-tooltip.top="$t('vacancies.udt')" translate="no">forklift</span>
                        <span v-if="vacancy.medical_book" class="material-symbols-outlined text-red-600"
                            v-tooltip.top="$t('vacancies.medical_book')" translate="no">medical_information</span>
                        <span v-if="vacancy.experience" class="material-symbols-outlined text-sky-600"
                            v-tooltip.top="$t('vacancies.experience')" translate="no">work_history</span>
                        <span v-if="vacancy.housing_copmensation" class="material-symbols-outlined"
                            v-tooltip.top="$t('vacancies.housing_compensation')" translate="no">real_estate_agent</span>
                        <span v-if="vacancy.night_shifts" class="material-symbols-outlined text-rose-400"
                            v-tooltip.top="$t('vacancies.night_shifts')" translate="no">dark_mode</span>
                    </div>
                </div>
            </div>
        </div>

        <div class="flex flex-row justify-between items-end mt-4 mt-auto p-3">
            <div>
                <i v-if="vacancy.active" class="pi pi-check text-green-500 me-1 !text-xs"></i>
                <i v-else class="pi pi-times text-red-400 me-1 !text-xs"></i>
                <small class="font-medium">{{ vacancy.updated_at }}</small>
            </div>
            <div class="flex flex-row flex-wrap gap-2 items-center justify-end mt-2">
                <div class="flex flex-row gap-0">
                    <span v-for="gender in vacancy.allowed_genders?.sort()"
                        class="material-symbols-outlined text-blue-600" :class="genders[gender].class" translate="no">{{
                        genders[gender].icon }}</span>
                </div>
            </div>
            <div class="flex flex-row gap-2">

                <Button v-if="hasPermission('edit-vacancy')" as="router-link" icon="pi pi-pencil" outlined rounded
                    :to="route('vacancy.edit', vacancy.id)"></Button>
                <Button v-else-if="hasPermission('show-vacancy')" as="router-link" icon="pi pi-eye" outlined rounded
                    :to="route('vacancy.show', vacancy.id)"></Button>
                <Button v-if="hasPermission('delete-vacancy')" icon="pi pi-trash" outlined rounded severity="danger"
                    @click="destroy($event, vacancy.id)"></Button>
            </div>
        </div>
    </div>
</template>

<script>
import { usePermissions } from '../Composables/usePermissions';

export default {
    emits: ['destroy'],
    props: {
        vacancy: {
            type: Object,
            required: true
        }
    },
    setup() {
        const genders = {
            1: {
                icon: 'male',
                class: 'text-blue-600'
            },
            2: {
                icon: 'female',
                class: 'text-pink-600'
            },
            3: {
                icon: 'family_restroom',
                class: 'text-indigo-600 text-xl'
            }
        };
        const { hasRole, hasPermission } = usePermissions();

        return { genders, hasRole, hasPermission }
    },
    methods: {
        destroy(event, id) {
            this.$emit('destroy', event, id);
        }
    }
}
</script>
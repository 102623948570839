<template>
    <div 
        class="transition-colors ease-out delay-150 duration-300 relative flex flex-col items-start p-2 mt-3 rounded-lg cursor-pointer bg-opacity-90 group hover:bg-opacity-100"
        :class="(lead.returned ? 'bg-red-500' : 'bg-white') + ' ' + (nonDraggable ? 'non-draggable' : '')"
        draggable="true"
     >
        <div class="flex flex-row justify-between w-full items-center mb-2">
            
            <div class="flex flex-row items-center">
                <h4 class="text-lg font-medium my-0 me-2">{{ lead.full_name }}</h4>
                <Badge class="text-sm/8" v-if="lead?.age > 0" :value="lead.age" severity="info" size="small"></Badge>
            </div>
            <div class="flex items-center">
                <span v-if="lead.sex == 1" class="material-symbols-outlined text-blue-600" translate="no">male</span>
                <span v-else-if="lead.sex == 2" class="material-symbols-outlined text-pink-600" translate="no">female</span>
                <span v-else-if="lead.sex == 3" class="material-symbols-outlined text-indigo-600 text-xl" translate="no">family_restroom</span>
                <Button type="button" icon="pi pi-ellipsis-v" @click="nextStage" severity="secondary" text class="md:!hidden !text-black ms-2"/>
            </div>
            
        </div>
        <!-- <div class="flex flex-row flex-wrap gap-1">
            <Tag class="text-xs" v-for="i in 10" :value="'tag '+i"></Tag>
        </div> -->
        <div class="flex flex-row flex-wrap gap-x-4 gap-y-1">
            <div class="flex flex-row items-center" v-for="record in lead.phones">
                <span class="material-symbols-outlined text-sm me-1" translate="no">phone</span>
                <span class="text-sm">{{ record.phone }}</span>
            </div>
        </div>
        <div class="flex flex-col mt-1">
            <div class="flex flex-row items-center" v-if="lead?.city?.name">
                <span class="material-symbols-outlined text-sm me-1" translate="no">person_pin_circle</span>
                <span class="text-sm">{{ lead?.city?.name }}</span>
            </div>
        </div>
        <div class="flex flex-col mt-1">
            <div class="flex flex-row items-center" v-if="lead.vacancy?.title">
                <span class="material-symbols-outlined text-sm me-1" translate="no">work</span>
                <span class="text-sm">{{ lead.vacancy?.title }}</span>
            </div>
        </div>
        <div class="flex flex-row justify-between w-full mt-2 justify-self-end items-center">
            <div class="flex items-center self-end">
                <div class="flex flex-col">
                    <div class="flex flex-row items-center">
                        <span class="material-symbols-outlined text-slate-500 text-sm me-1 mt-[-2px]" translate="no">today</span>
                        <span class="text-xs text-slate-500 leading-[0.8rem]">{{ lead.created_date }}</span>
                    </div>
                    <div class="flex flex-row items-center" v-if="hasRole('Manager') || hasRole('Admin')">
                        <span class="material-symbols-outlined text-slate-500 text-sm me-1" translate="no">person</span>
                        <span class="text-sm text-slate-500">{{ lead?.responsible?.lastname }}</span>
                    </div>
                </div>
            </div>
            <div class="flex flex-row gap-2">
                <Button v-if="hasRole('Manager') || hasRole('Admin')" icon="pi pi-trash" outlined rounded severity="danger" @click="destroy($event, lead.id)"></Button>
                <Button as="router-link" :to="route('lead.edit', lead)" rounded outlined severity="secondary" style="padding: 0.5rem 0.6rem;">
                    <i class="pi pi-pencil"></i>
                </Button>
                <Button @click="phoneCall(lead.phones)" class="!inline-flex md:!hidden" rounded severity="success" style="padding: 0.5rem 0.6rem;">
                    <i class="pi pi-phone"></i>
                </Button>
            </div>
        </div>
        <div v-if="errors" class="flex flex-col mt-2 items-center w-full">
            <small class="text-red-500" v-for="error in errors">{{ error }}</small>
        </div>
    </div>
</template>

<script>
import { router } from '@inertiajs/vue3';
import { Link } from '@inertiajs/vue3';
import { usePermissions } from '../Composables/usePermissions';
import { useConfirm } from 'primevue/useconfirm';

export default {
    components: { Link },
    props: {
        lead: {
            type: Object,
            required: true
        },
        errors: {
            type: Array,
            required: false,
            default: []
        },
        nonDraggable: {
            type: Boolean,
            required: false,
            default: false
        },
        stages: Object,
        activeStage: Number
    },
    setup(props) {
        const { hasRole, hasPermission } = usePermissions();
        const confirmPopup = useConfirm();
        return { hasRole, hasPermission, confirmPopup };
    },
    methods: {
        nextStage(event) {
            this.$emit('nextStage', event);
        },
        phoneCall(phones) {
            if (phones?.length) {
                window.location.href = 'tel:' + phones[0].phone;
            }
        },
        editLead(lead) {
            router.get();
        },
        destroy(event, id) {
            this.confirmPopup.require({
                target: event.currentTarget,
                message: 'Подтвердить удаление?',
                icon: 'pi pi-info-circle',
                rejectProps: {
                    label: 'Отменить',
                    severity: 'secondary',
                    outlined: true
                },
                acceptProps: {
                    label: 'Да',
                    severity: 'danger'
                },
                accept: () => {
                    router.delete(route('lead.destroy', id), { preserveState: false });
                }
            });
        },
    }
}
</script>
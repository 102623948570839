<template>
    <div class="select-button-complex flex flex-row gap-2 bg-[#f1f5f9] justify-center w-full md:w-auto py-1 rounded-md">
        <button v-for="item in options" class="flex flex-column align-center justify-center px-[0.75rem] py-[0.25rem] rounded-md" :class="activeClass(item.value)" @click.stop="toggleValue(item.value)">
            <span class="material-symbols-outlined d-inline" :class="item?.class" translate="no">{{ item.icon }}</span>
        </button>
    </div>
</template>

<script>
import { get } from '@vueuse/core/index.cjs';

export default {
    props: {
        options: {
            type: Array,
            required: true
        },
        modelValue: {
            type: Array,
            required: false,
            default: []
        }
    },
    data(){
        return {
            //values: []
        };
    },
    methods: {
        toggleValue(value) {
            if (!this.isActive(value)) {
                this.values.push(value);
            }
            else {
                if (this.isPositive(value)) {
                    const index = this.values.indexOf(value);
                    this.values[index] = '!' + value;
                }
                else {
                    const index = this.values.indexOf('!'+value);
                    this.values.splice(index, 1);
                }   
            }
            this.values = this.values;
        },
        isPositive(value) {
            return this.values.includes(value);
        },
        isNegative(value) {
            value = '!' + value;
            return this.values.includes(value);
        },
        isActive(value) {
            return this.isPositive(value) || this.isNegative(value);
        },
        activeClass(value) {
            let activeClass = '';
            if (this.values) {
                if (this.isActive(value)) {
                    activeClass += 'active ';
                }
                if (this.isNegative(value)) {
                    activeClass += 'negative';
                }
            }
            return activeClass;
        }
    },
    computed: {
        values: {
            get() {
                if (this.modelValue !== null)
                    return this.modelValue;
                else
                    return [];
            },
            set(val) {
                this.$emit('update:modelValue', val);
            }
        }
    }
}
</script>

<style lang="scss" scoped>
.select-button-complex{
    .active{
        box-shadow: 0px 1px 2px 0px rgba(0,0,0,0.02),0px 1px 2px 0px rgba(0,0,0,0.04);
        background-color: #fff;

        &.negative{
            position: relative;
            &:before, &:after {
                position: absolute;
                content: '';
                background: #334155;
                display: block;
                width: 80%;
                height: 1px;
                -webkit-transform: rotate(32deg);
                transform: rotate(32deg);
                /* center the X vertically and horizontally: */
                left: 0;
                right: 0;
                top: 0;
                bottom: 0;
                margin: auto;
            }
        }
    }
}
</style>
<template>
    <div class="flex flex-col gap-1 mb-6">
        <span class="block font-bold">{{ $t(`vacancies.${filterKey}`) }} <Button v-if="values?.length == 2" @click.stop="clear" icon="pi pi-times" severity="secondary" size="small" text rounded></Button></span>
        <div class="flex flex-row justify-between items-center gap-4">
            <InputNumber :modelValue="modelValue[0] ?? null" @update:modelValue="setMin" :placeholder="params.min?.toString()" :min="params.min" :max="params.max" inputClass="w-20"></InputNumber>
            <Slider :min="params.min" :max="params.max" :step="params.step" v-model="values" range class="w-full"></Slider>
            <InputNumber :modelValue="modelValue[1] ?? null" @update:modelValue="setMax" :placeholder="params.max?.toString()" :min="params.min" :max="params.max" inputClass="w-20"></InputNumber>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        filterKey: {
            type: String,
            required: true
        },
        params: {
            type: Object,
            required: true
        },
        modelValue: {
            type: Array,
            required: false,
            default: []
        }
    },
    methods: {
        setMin(value) {
            if (value && value != this.modelValue[0]) {
                let values = [...this.modelValue];
                values[0] = value;
                if (!values[1]) {
                    values[1] = this.params.max;
                }
                this.values = values;
            }
        },
        setMax(value) {
            if (value && value != this.modelValue[1]) {
                let values = [...this.modelValue];
                values[1] = value;
                if (!values[0]) {
                    values[0] = this.params.min;
                }
                this.values = values;
            }
        },
        clear() {
            this.$emit('update:modelValue', []);
        }
    },
    computed: {
        values: {
            get() {
                return this.modelValue;
            },
            set(newVal) {
                const oldVal = this.modelValue;
                if (
                    newVal.length == 2 &&
                    (oldVal[0] != newVal[0] || oldVal[1] != newVal[1])
                ) {
                    console.log('updated', newVal);
                    this.$emit('update:modelValue', newVal);
                }
            }
        }
    }
}
</script>
<template>
    <FileUpload :pt="fpt" name="logo"  accept="image/*" @select="selectFile" @remove="removeFile" :show-upload-button="false" :show-cancel-button="false">
        <template #header="{ chooseCallback, uploadCallback, clearCallback, files }">
            <Button :disabled="files.length > 0 || uploadedImage" @click="chooseCallback()" severity="primary">Выбрать файл</Button>
        </template>
        <template #content="{ files, uploadedFiles, removeUploadedFileCallback, removeFileCallback }">
            <div v-if="files.length > 0" class="grid grid-cols-12 items-center self-end">
                <template v-for="(file, index) of files" :key="file.name + file.type + file.size">
                    <div class="col-span-2 pt-2">
                        <!-- <Image :src="file.objectURL" alt="Image" preview class="w-full h-full max-h-20 object-scale-down"/> -->
                        <img :src="file.objectURL" class="w-full h-full max-h-20 object-contain" alt="">
                    </div>
                    <div class="col-span-8 text-center">{{file.name}}</div>
                    <div class="col-span-2 text-end">
                        <Button size="small" @click="removeFileCallback()" icon="pi pi-times" rounded outlined severity="danger" :disabled="!files || files.length === 0"></Button>
                    </div>
                </template>
            </div>

            <div v-if="uploadedImage" class="grid grid-cols-12 items-center self-end">
                <div class="col-span-2 pt-2">
                    <!-- <Image :src="file.objectURL" alt="Image" preview class="w-full h-full max-h-20 object-scale-down"/> -->
                    <img :src="uploadedImage" class="w-full h-full max-h-20 object-contain" alt="">
                </div>
                <div class="col-span-8 text-center"></div>
                <div class="col-span-2 text-end">
                    <Button size="small" @click="removeUploadedFile()" icon="pi pi-times" rounded outlined severity="danger"></Button>
                </div>
            </div>
        </template>
    </FileUpload>
</template>

<script>
export default {
    props: {
        modelValue: {
            type: [File, String],
            required: false,
            default: null
        },
        uploadedImage: {
            type: String,
            required: false,
            default: null
        }
    },
    data() {
        return {
            fpt: {
                root: {
                    class: 'w-full h-full flex flex-col justify-between',
                    style: 'border: none;'
                },
                header: {
                    style: 'padding: 0!important;'
                },
                content: {
                    //class: 'mt-2',
                    style: 'padding: 0!important;'
                }
            },
        };
    },
    methods: {
        selectFile(event) {
            this.value = event.files[0];
        },
        removeFile() {
            this.value = null;
        },
        removeUploadedFile() {
            this.$emit('update:uploadedImage', null);
        }
    },
    computed: {
        value: {
            get() {
                return this.modelValue;
            },
            set(val) {
                this.$emit('update:modelValue', val);
            }
        }
    }
}
</script>

<template>
    <div class="card !p-0">
        <ContextMenu ref="cm" :model="menuModel" @hide="selectedProduct = null" />
        <div v-if="!loaded" class="flex w-full h-[80dvh] items-center">
            <ProgressSpinner class="m-auto !w-1/5 !h-1/5" strokeWidth="4" fill="transparent" animationDuration=".5s"/>
        </div>
    
        <DataTable 
            v-if="loaded"
            selectionMode="single"
            :editMode="editMode ?'cell':null" 
            @cell-edit-complete="onCellEditComplete"
            v-model:filters="filters"
            v-model:sortField="sortField"
            v-model:sortOrder="sortOrder"
            :value="vacancies.data" 
            paginator 
            showGridlines 
            scrollable
            scrollHeight="77dvh"
            :rows="vacancies.meta.per_page"
            :totalRecords="vacancies.meta.total" 
            :lazy="true"
            @filter="onFilter"
            @page="onPage"
            @sort="onSort"
            dataKey="id"
            filterDisplay="menu" 
            :loading="false" 
            contextMenu 
            v-model:contextMenuSelection="selectedVacancy"
            @rowContextmenu="onRowContextMenu"
        >
            <template #header>
                <div class="flex justify-between">
                    <Button type="button" icon="pi pi-filter-slash" :label="$t('Clear')" outlined @click="clearFilter()" />
                    <IconField>
                        <InputIcon>
                            <i class="pi pi-search" />
                        </InputIcon>
                        <InputText v-model="filters['search'].value" :placeholder="$t('search')" />
                    </IconField>
                </div>
            </template>
            <!-- <template #empty> {{ $t('No customers found.') }} </template> -->
            <!-- <template #loading> {{ $t('Loading customers data. Please wait.') }}</template> -->

            <Column field="active"  dataType="boolean" bodyClass="!text-center">
                <template #body="{ data, field, index }">
                    <i v-if="!isLoading(field, index)" class="pi" :class="{ 'pi-check-circle text-green-500 ': data.active, 'pi-times-circle text-red-500': !data.active }"></i>
                    <i v-else class="pi pi-spin pi-cog"></i>
                </template>
                <template #filter="{ filterModel }">
                    <label for="active-filter" class="font-bold"> {{ $t('vacancies.active') }} </label>
                    <Checkbox v-model="filterModel.value" :indeterminate="filterModel.value === null" binary inputId="active-filter" />
                </template>
                <template #filtericon>
                    <i :class="isFilterActive('active') ? 'pi pi-filter-fill' : 'pi pi-filter'"></i>
                </template>
                <template #editor="{ data, field, index }">
                    <Checkbox  v-model="data.active" :binary="true" />
                </template>
                
            </Column>

            <Column field="hot"  dataType="boolean" bodyClass="!text-center">
                <template #body="{ data, field, index }">
                    <span v-if="!isLoading(field, index)" v-tooltip="$t('vacancies.hot')" class="cursor-pointer material-symbols-outlined" :class="{ 'text-rose-400': data.hot, 'text-[#64748b]': !data.hot }" translate="no">mode_heat</span>
                    <i v-else class="pi pi-spin pi-cog"></i>
                </template>
                <template #filter="{ filterModel }">
                    <label for="hot-filter" class="font-bold"> {{ $t('vacancies.hot') }} </label>
                    <Checkbox v-model="filterModel.value" :indeterminate="filterModel.value === null" binary inputId="hot-filter" />
                </template>
                <template #filtericon>
                    <i :class="isFilterActive('hot') ? 'pi pi-filter-fill' : 'pi pi-filter'"></i>
                </template>
                <template #editor="{ data, field, index }">
                    <Checkbox  v-model="data.hot" :binary="true" />
                </template>
                
            </Column>

            <Column :header="$t('vacancies.updated_at')" field="updated_at" filterField="date" sortField="updated_at" :sortable="true" dataType="date" bodyClass="!text-center">
                <template #body="{ data }">
                    <small>{{ data.updated_at }}</small>
                </template>
                <!-- <template #filter="{ filterModel }">
                    <DatePicker v-model="filterModel.value" dateFormat="dd.mm.yyyy" placeholder="dd.mm.yyyy" />
                </template>
                <template #filtericon>
                    <i :class="isFilterActive('updated_at') ? 'pi pi-filter-fill' : 'pi pi-filter'"></i>
                </template> -->
            </Column>

            <Column field="title" :header="$t('vacancies.name')" style="min-width: 12rem">
                <template #body="{ data }">
                    <template v-if="!editMode">
                        {{ data.title }}
                    </template>
                    <Link v-else :href="route('vacancy.edit', data.id)" class="hover:!underline">{{ data.title }}</Link>
                </template>
                <template #filter="{ filterModel }">
                    <InputText v-model="filterModel.value" type="text" :placeholder="$t('search')" />
                </template>
                <template #filtericon>
                    <i :class="isFilterActive('title') ? 'pi pi-filter-fill' : 'pi pi-filter'"></i>
                </template>
            </Column>

            <Column field="work_city" :header="$t('vacancies.work_city')" filterField="work_city" :showFilterMatchModes="false" style="min-width: 10rem">
                <template #body="{ data }">
                    <div class="flex flex-row gap-1">
                        <span>{{ data.work_city }}</span>
                    </div>
                </template>
                <template #filter="{ filterModel }">
                    <AutoComplete  
                        v-model="selectedWorkCity" 
                        :suggestions="cities" 
                        optionLabel="full_name" 
                        optionValue="id" 
                        class=""
                        forceSelection
                        :loading="citySelectLoading"
                        @complete="fetchCities"  
                        fluid
                        :placeholder="$t('City')"
                    />
                </template>
                <template #filtericon>
                    <i :class="isFilterActive('work_city') ? 'pi pi-filter-fill' : 'pi pi-filter'"></i>
                </template>
                <template #filterclear="{ filterCallback }">
                    <Button type="button" @click="workCityFilterClear(filterCallback)" outlined size="small" :label="$t('Clear')"></Button>
                </template>
            </Column>

            <Column field="closest_city" :header="$t('vacancies.closest_city')" filterField="closest_city_km" :showFilterMatchModes="false" style="min-width: 10rem">
                <template #body="{ data }">
                    <div class="flex flex-row gap-1">
                        <span>{{ data.closest_city }}</span>
                        <span class="text-gray-500">
                            {{ data.closest_city_km }}<small v-if="data.closest_city_km">km</small>
                        </span>
                    </div>
                </template>
                <template #filter="{ filterModel }">
                    <AutoComplete  
                        v-model="selectedClosestCity" 
                        :suggestions="cities" 
                        optionLabel="full_name" 
                        optionValue="id" 
                        class=""
                        forceSelection
                        :loading="citySelectLoading"
                        @complete="fetchCities"  
                        fluid
                        :placeholder="$t('City')"
                    />
                    <InputNumber v-model="filterModel.value" :useGrouping="false" :placeholder="'km'" fluid />
                </template>
                <template #filtericon>
                    <i :class="isFilterActive('closest_city') || isFilterActive('closest_city_km') ? 'pi pi-filter-fill' : 'pi pi-filter'"></i>
                </template>
                <template #filterclear="{ filterCallback }">
                    <Button type="button" @click="closestCityFilterClear(filterCallback)" outlined size="small" :label="$t('Clear')"></Button>
                </template>
            </Column>

            <Column field="allowed_genders" headerClass="filter-center" :showFilterMatchModes="false" bodyClass="!text-center">
                <template #body="{ data }">
                    <div class="flex flex-row gap-0">
                        <span v-for="gender in data.allowed_genders?.sort()"
                            class="material-symbols-outlined text-blue-600" :class="genderIcons[gender].class" translate="no">{{
                            genderIcons[gender].icon }}
                        </span>
                    </div>
                </template>
                <template #filter="{ filterModel }">
                    <SelectButton v-model="filterModel.value" multiple :options="genders" optionLabel="icon" optionValue="value" dataKey="value" aria-labelledby="custom" class="justify-center w-full md:justify-start md:w-auto">
                        <template #option="slotProps">
                            <span :class="slotProps.option?.class">{{ slotProps.option?.icon }}</span>
                        </template>
                    </SelectButton>
                </template>
                <template #filtericon>
                    <i :class="isFilterActive('allowed_genders') ? 'pi pi-filter-fill' : 'pi pi-filter'"></i>
                </template>
            </Column>

            <Column field="requirements" headerClass="filter-center" :showFilterMatchModes="false" bodyClass="!text-center">
                <template #body="{ data }">
                    <div class="flex flex-row gap-1">
                        <span v-if="data.udt" class="material-symbols-outlined text-violet-600"
                            v-tooltip.top="$t('vacancies.udt')" translate="no">forklift</span>
                        <span v-if="data.medical_book" class="material-symbols-outlined text-red-600"
                            v-tooltip.top="$t('vacancies.medical_book')" translate="no">medical_information</span>
                        <span v-if="data.experience" class="material-symbols-outlined text-sky-600"
                            v-tooltip.top="$t('vacancies.experience')" translate="no">work_history</span>
                        <span v-if="data.housing_copmensation" class="material-symbols-outlined"
                            v-tooltip.top="$t('vacancies.housing_compensation')" translate="no">real_estate_agent</span>
                        <span v-if="data.night_shifts" class="material-symbols-outlined text-rose-400"
                            v-tooltip.top="$t('vacancies.night_shifts')" translate="no">dark_mode</span>
                    </div>
                </template>
                <template #filter="{ filterModel }">
                    <SelectButtonComplex :options="requirements" v-model="filterModel.value"/>
                </template>
                <template #filtericon>
                    <i :class="isFilterActive('requirements') ? 'pi pi-filter-fill' : 'pi pi-filter'"></i>
                </template>
            </Column>

            <Column :header="$t('vacancies.number_of_workplaces_short')" field="number_of_workplaces" filterField="number_of_workplaces" sortField="number_of_workplaces" :sortable="true" dataType="numeric" style="min-width: 10rem">
                <template #body="{ data, field, index }">
                    <span v-if="!isLoading(field, index)">{{ data.number_of_workplaces }}</span>
                    <div v-else class="flex flex-row gap-2">
                        <i class="pi pi-spin pi-cog"></i>
                        <small>{{ $t('Saving...') }}</small>
                    </div>
                    
                </template>
                <template #filter="{ filterModel }">
                    <InputNumber v-model="filterModel.value" :useGrouping="false" />
                </template>
                <template #filtericon>
                    <i :class="isFilterActive('number_of_workplaces') ? 'pi pi-filter-fill' : 'pi pi-filter'"></i>
                </template>
                <template #editor="{ data, field }">
                    <InputNumber v-model="data.number_of_workplaces" :useGrouping="false" autofocus fluid />
                </template>
            </Column>

            <Column :header="$t('vacancies.base_rate_short')" filterField="base_rate" sortField="base_rate" :sortable="true" dataType="numeric" style="min-width: 10rem">
                <template #body="{ data }">
                    {{ formatCurrency(data.base_rate) }}
                </template>
                <template #filter="{ filterModel }">
                    <InputNumber v-model="filterModel.value" mode="currency" currency="PLN" />
                </template>
                <template #filtericon>
                    <i :class="isFilterActive('base_rate') ? 'pi pi-filter-fill' : 'pi pi-filter'"></i>
                </template>
            </Column>

            <Column :header="$t('vacancies.student_rate_short')" filterField="student_rate" sortField="student_rate" :sortable="true" dataType="numeric" style="min-width: 10rem">
                <template #body="{ data }">
                    {{ formatCurrency(data?.student_rate) }}
                </template>
                <template #filter="{ filterModel }">
                    <InputNumber v-model="filterModel.value" mode="currency" currency="PLN" />
                </template>
                <template #filtericon>
                    <i :class="isFilterActive('student_rate') ? 'pi pi-filter-fill' : 'pi pi-filter'"></i>
                </template>
            </Column>

            <Column :header="$t('vacancies.young_rate_short')" filterField="young_rate" sortField="young_rate" :sortable="true" dataType="numeric" style="min-width: 10rem">
                <template #body="{ data }">
                    {{ formatCurrency(data?.young_rate) }}
                </template>
                <template #filter="{ filterModel }">
                    <InputNumber v-model="filterModel.value" mode="currency" currency="PLN" />
                </template>
                <template #filtericon>
                    <i :class="isFilterActive('currency') ? 'pi pi-filter-fill' : 'pi pi-filter'"></i>
                </template>
            </Column>

            <Column :header="$t('vacancies.max_age_short')" filterField="max_age" sortField="max_age" :sortable="true" dataType="numeric" style="min-width: 10rem">
                <template #body="{ data }">
                    {{ data.max_age }}
                </template>
                <template #filter="{ filterModel }">
                    <InputNumber v-model="filterModel.value" :useGrouping="false" />
                </template>
                <template #filtericon>
                    <i :class="isFilterActive('max_age') ? 'pi pi-filter-fill' : 'pi pi-filter'"></i>
                </template>
            </Column>

            <Column  v-if="hasPermission('edit-vacancy')" :header="$t('vacancies.company')" filterField="company" :showFilterMatchModes="false" :filterMenuStyle="{ width: '14rem' }" style="min-width: 14rem">
                <template #body="{ data }">
                    <div class="flex items-center gap-2">
                        <img v-if="data.company?.logo" :alt="data.company?.name" :src="data.company?.logo" style="width: 32px" />
                        <span>{{ data.company?.name }}</span>
                    </div>
                </template>
                <template #filter="{ filterModel }">
                    <MultiSelect v-model="filterModel.value" :options="companies" optionValue="id" optionLabel="name" :placeholder="$t('Any')">
                        <template #option="slotProps">
                            <div class="flex items-center gap-2">
                                <img v-if="slotProps.option?.logo" :alt="slotProps.option?.company" :src="slotProps.option?.logo" style="width: 32px" />
                                <span>{{ slotProps.option?.name }}</span>
                            </div>
                        </template>
                    </MultiSelect>
                </template>
                <template #filtericon>
                    <i :class="isFilterActive('company') ? 'pi pi-filter-fill' : 'pi pi-filter'"></i>
                </template>
            </Column>

            <Column :header="$t('vacancies.facility')" filterField="facility" :showFilterMatchModes="false" :filterMenuStyle="{ width: '14rem' }" style="min-width: 14rem">
                <template #body="{ data }">
                    {{ data.facility?.name }}
                </template>
                <template #filter="{ filterModel }">
                    <MultiSelect v-model="filterModel.value" :options="facilities" optionValue="id" optionLabel="name" :placeholder="$t('Any')">
                        <template #option="slotProps">
                            {{ slotProps.option?.name }}
                        </template>
                    </MultiSelect>
                </template>
                <template #filtericon>
                    <i :class="isFilterActive('facility') ? 'pi pi-filter-fill' : 'pi pi-filter'"></i>
                </template>
            </Column>

            <Column :header="$t('vacancies.contract_type_short')" filterField="contract_type" :showFilterMatchModes="false" :filterMenuStyle="{ width: '14rem' }" style="min-width: 14rem">
                <template #body="{ data }">
                    <tag>
                        {{ data?.contract_type_name_short }}
                    </tag>
                </template>
                <template #filter="{ filterModel }">
                    <MultiSelect v-model="filterModel.value" :options="contractTypes" optionValue="id" optionLabel="name" :placeholder="$t('Any')">
                        <template #option="slotProps">
                            {{ slotProps.option?.name }}
                        </template>
                    </MultiSelect>
                </template>
                <template #filtericon>
                    <i :class="isFilterActive('contract_type') ? 'pi pi-filter-fill' : 'pi pi-filter'"></i>
                </template>
            </Column>

            <Column :header="$t('vacancies.housing_type')" filterField="housing_type" :showFilterMatchModes="false" :filterMenuStyle="{ width: '14rem' }" style="min-width: 14rem">
                <template #body="{ data }">
                    <div class="flex items-center gap-2">
                        <tag v-for="ht in data.housing_type_names">{{ ht }}</tag>
                    </div>
                </template>
                <template #filter="{ filterModel }">
                    <MultiSelect v-model="filterModel.value" :options="housingTypes" optionValue="id" optionLabel="name" :placeholder="$t('Any')">
                        <template #option="slotProps">
                            {{ slotProps.option?.name }}
                        </template>
                    </MultiSelect>
                </template>
                <template #filtericon>
                    <i :class="isFilterActive('housing_type') ? 'pi pi-filter-fill' : 'pi pi-filter'"></i>
                </template>
            </Column>

            <Column :header="$t('vacancies.citizenships')" filterField="citizenship" :showFilterMatchModes="false" :filterMenuStyle="{ width: '14rem' }" style="min-width: 14rem">
                <template #body="{ data }">
                    <div class="flex items-center gap-2" v-for="citizenship in data.citizenships">
                        <img alt="flag" src="/img/flag_placeholder.png" :class="`flag flag-${citizenship?.code}`" style="width: 24px" />
                        <span>{{ citizenship?.name }}</span>
                    </div>
                </template>
                <template #filter="{ filterModel }">
                    <MultiSelect v-model="filterModel.value" :options="citizenships" optionValue="id" optionLabel="name" :placeholder="$t('Any')">
                        <template #option="slotProps">
                            <div class="flex items-center gap-2">
                                <img alt="flag" src="/img/flag_placeholder.png" :class="`flag flag-${slotProps.option?.code}`" style="width: 24px" />
                                <span>{{ slotProps.option?.name }}</span>
                            </div>
                        </template>
                    </MultiSelect>
                </template>
                <template #filtericon>
                    <i :class="isFilterActive('citizenship') ? 'pi pi-filter-fill' : 'pi pi-filter'"></i>
                </template>
            </Column>

        </DataTable>
    </div>
</template>

<script>
import { FilterMatchMode, FilterOperator } from '@primevue/core/api';
import { queryToFilter, filterToQuery } from '../Composables/dtFilterAdapters';
import SelectButtonComplex from '../Components/SelectButtonComplex.vue';
import { router, Link } from '@inertiajs/vue3';
import { trans } from 'laravel-vue-i18n';
import { usePermissions } from '../Composables/usePermissions';

export default {
    props: {
        filterData: {
            type: Object,
            required: false,
            default: {}
        },
        filterLogic: {
            type: Object,
            required: false,
            default: {}
        },
        sortLogic: {
            type: String,
            required: false,
            default: null
        },
        vacancies: {
            type: Object,
            required: true
        },
        companies: {
            type: Array,
            required: true
        },
        facilities: {
            type: Array,
            required: true
        },
        citizenships: {
            type: Array,
            required: true
        },
        genders: {
            type: Array,
            required: true
        },
        genderIcons: {
            type: Object,
            required: true
        },
        requirements: {
            type: Array,
            required: true
        },
        contractTypes: {
            type: Array,
            required: true
        },
        housingTypes: {
            type: Array,
            required: true
        },
        editMode: {
            type: Boolean,
            required: false,
            default: false
        }
    },
    components: { SelectButtonComplex, Link },
    setup(props) {
        const { hasRole, hasPermission } = usePermissions();
        const defaultFilters = {
            search: { value: null, matchMode: FilterMatchMode.EQUALS },
            active: { value: null, matchMode: FilterMatchMode.EQUALS },
            hot: { value: null, matchMode: FilterMatchMode.EQUALS },
            title: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
            closest_city: { value: null, matchMode: FilterMatchMode.EQUALS},
            work_city: { value: null, matchMode: FilterMatchMode.EQUALS},
            closest_city_km: { value: null, matchMode: FilterMatchMode.EQUALS},
            number_of_workplaces: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }] },
            base_rate: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }] },
            student_rate: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }] },
            young_rate: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }] },
            max_age: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }] },
            company: { value: null, matchMode: FilterMatchMode.IN },
            facility: { value: null, matchMode: FilterMatchMode.IN },
            contract_type: { value: null, matchMode: FilterMatchMode.IN },
            housing_type: { value: null, matchMode: FilterMatchMode.IN },
            allowed_genders: { value: null, matchMode: FilterMatchMode.IN },
            requirements: { value: null, matchMode: FilterMatchMode.IN },
            citizenship: { value: null, matchMode: FilterMatchMode.IN },
            updated_at: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.DATE_IS }] },
        }

        return { defaultFilters, hasRole, hasPermission };
    },
    data() {
        return {
            filters: null,
            sortField: null,
            sortOrder: null,
            loaded: false,
            fieldLoading: [],
            selectedVacancy: null,
            cities: [],
            citySelectLoading: false,
            selectedClosestCity: null,
            selectedWorkCity: null,
        };
    },
    created() {
        this.initFilters();
        this.initSort();
    },
    mounted() {
        //this.loaded = true;
        setTimeout(() => {
            this.loaded = true;
        }, 1);
        // this.$nextTick(() => {
        //     this.loaded = true;
        // });
    },
    computed:{
        menuModel()
        {   
            let menu = [];
            if(this.hasPermission('show-vacancy')){
                menu.push({label: 'Просмотр', icon: 'pi pi-fw pi-search', command: () => router.get(route('vacancy.show', this.selectedVacancy))});
            }

            if(this.hasPermission('edit-vacancy')){
                menu.push({label: 'Изменить', icon: 'pi pi-fw pi-pencil', command: () => router.get(route('vacancy.edit', this.selectedVacancy))});
            }

            if(this.hasPermission('delete-vacancy')){
                menu.push({label: 'Удалить', icon: 'pi pi-fw pi-times', command: () => this.destroy(this.selectedVacancy)});
            }

            return menu;
        }
    },
    methods: {
        onRowContextMenu(event) {
            if(this.menuModel.length > 0){
                this.$refs.cm.show(event.originalEvent);
            }else{
                return false;
            }
        },
        destroy(vacancy) {
            this.$emit('destroy', vacancy);
        },
        onCellEditComplete(event) {
            const field = event.field;
            const newValue = event.newValue;
            const value = event.value;
            const index = event.index;
           
            if (value != newValue) {
                router.post(route('set-field-value', { vacancy: this.vacancies.data[index] }),
                    {
                        field_name: field,
                        value: newValue
                    },
                    {
                        preserveScroll: true,
                        preserveState: true,
                        onBefore: (visit) => {
                            this.fieldLoading.push({
                                index: index,
                                field: field
                            });
                        },
                        onFinish: (visit) => {
                            const loadingIndex = this.fieldLoading.findIndex(x => (x.index == index && x.field == field));
                            this.fieldLoading.splice(loadingIndex, 1);
                        }
                    },
                );
            }
        },
        isLoading(field, index) {
            if (this.fieldLoading && Array.isArray(this.fieldLoading)) {
                const found = this.fieldLoading.findIndex(x => (x.index == index && x.field == field));
                return found != -1;
            }
        },
        onFilter(event = null) {
            let filters = this.filters;
            if (event)
                filters = event.filters;
            this.$emit('filter', filterToQuery(filters));
        },
        onPage(event) {
            this.$emit('page', event);
        },
        onSort(event) {
            this.$emit('sort', event);
        },
        formatCurrency(value) {
            return value;
            //console.log(value);
            //value = 15.12;
            return value.toLocaleString('ru-RU', { style: 'currency', currency: 'PLN' });
        },
        clearFilter() {
            this.$emit('filter', null);
        },
        initFilters() {
            this.filters = queryToFilter(this.defaultFilters, this.filterData, this.filterLogic);
        },
        initSort() {
            if (this.sortLogic) {
                if (this.sortLogic.startsWith('-')) {
                    this.sortField = this.sortLogic.replace('-', '');
                    this.sortOrder = -1;
                }
                else {
                    this.sortField = this.sortLogic;
                    this.sortOrder = 1;
                }
            }
        },
        isFilterActive(key) {
            let res = false;
            if (this.filters.hasOwnProperty(key)) {
                if (this.filters[key].hasOwnProperty('value')) {
                    return this.filters[key]['value'] !== null;
                }
                else if (this.filters[key].hasOwnProperty('constraints')) {
                    this.filters[key].constraints.forEach((single) => {
                        if (single.value !== null) {
                            res = true;
                            return;
                        }
                    });
                }
            }
            return res;
        },
        fetchCities(event) {
            this.citySelectLoading = true;

            axios
                .post('/ajax/select2', {
                    q: event.query,
                    selected: this.filters.closest_city.value,
                    attribute: 'full_name',
                    related_model: 'App\\Models\\City',
                    resource: 'App\\Http\\Resources\\CityResource',
                })
                .then(response => {
                    this.cities = response.data.data;
                    this.citySelectLoading = false;
                });
        },
        closestCityFilterClear(filterCallback) {
            filterCallback();
            this.selectedCity = null;
            this.filters.closest_city_km.value = null;
            this.filters.closest_city.value = null;
            this.onFilter();
        },
        workCityFilterClear(filterCallback) {
            filterCallback();
            this.selectedWorkCity = null;
            this.filters.work_city.value = null;
            this.onFilter();
        },
    },
    watch: {
        'filters.search.value': function (val) {
            this.onFilter();
        },
        selectedClosestCity(city) {
            if (city && city.hasOwnProperty('id')) {
                this.filters.closest_city.value = city.id;
            }
            else {
                this.filters.closest_city.value = null;
            }
        },
        selectedWorkCity(city) {
            if (city && city.hasOwnProperty('id')) {
                this.filters.work_city.value = city.id;
            }
            else {
                this.filters.work_city.value = null;
            }
        }
    }
};
</script>

<style lang="scss">
.p-datatable-thead{
    top: -2px!important;
    .filter-center .p-datatable-filter.p-datatable-popover-filter{
        margin: auto !important;
    }
}
</style>
<template>
    <Head :title="$t('leads.moderation_title')"/>

    <Toolbar class="mb-4 !border-none">
        <template #start id="tollbar-start">
            <div class="font-medium text-xl">{{ $t('leads.moderation_title') }}</div>
        </template>

        <template #end>
            <IconField>
                <InputIcon>
                    <i class="pi pi-search" />
                </InputIcon>
                <InputText :placeholder="$t('search')" v-model="search"/>
            </IconField>
        </template>
    </Toolbar>

    <div class="card">
        <DataTable v-model:editingRows="editingRows" :value="leads" editMode="row" dataKey="id" @row-edit-save="onRowEditSave"
            :pt="{
                table: { style: 'min-width: 50rem' },
                column: {
                    bodycell: ({ state }) => ({
                        style:  state['d_editing']&&'padding-top: 0.75rem; padding-bottom: 0.75rem'
                    })
                }
            }"
        >
            <Column field="full_name" :header="$t('leads.lead')" style="width: 20%">
                <template #editor="{ data, field }">
                    <Link :href="route('lead.edit', data.lead_id)" class="h6 mb-0 text-sm">{{ data[field] }}</Link>
                </template>
                <template #body="{ data, field }">
                    <Link :href="route('lead.edit', data.lead_id)" class="h6 mb-0 text-sm">{{ data[field] }}</Link>
                </template>
            </Column>
            <Column field="recruited_date" :header="$t('leads.recruited_date')" style="width: 20%">
                <template #body="{ data, field }">
                    {{ fdt(data[field]) }}
                </template>
                <template #editor="{ data, field }">
                    <DatePicker dateFormat="dd.mm.yy" v-model="data[field]" :showIcon="true" @update:modelValue="handleChange(data.id, field, $event)"/>
                </template>
            </Column>
            <Column field="first_workday" :header="$t('leads.first_workday')" style="width: 20%">
                <template #body="{ data, field }">
                    {{ fdt(data[field]) }}
                </template>
                <template #editor="{ data, field }">
                    <DatePicker dateFormat="dd.mm.yy" v-model="data[field]"  :showIcon="true"  @update:modelValue="handleChange(data.id, field, $event)"/>
                </template>
            </Column>
            <Column field="leave_date" :header="$t('leads.leave_date')" style="width: 20%">
                <template #body="{ data, field }">
                    {{ fdt(data[field]) }}
                </template>
                <template #editor="{ data, field }">
                    <DatePicker dateFormat="dd.mm.yy"  v-model="data[field]" :showIcon="true"  @update:modelValue="handleChange(data.id, field, $event)"/>
                </template>
            </Column>
            <Column field="worked_days" :header="$t('leads.worked_days')" style="width: 20%">
                <template #editor="{ data, field }">
                    <InputText v-model="data[field]"  @update:modelValue="handleChange(data.id, field, $event)"/>
                </template>
            </Column>
            <Column field="required_workdays" :header="$t('leads.required_workdays')" style="width: 20%">
                <template #editor="{ data, field }">
                    <InputText v-model="data[field]" @update:modelValue="handleChange(data.id, field, $event)"/>
                </template>
            </Column>
            <Column field="vacancy_title" :header="$t('leads.vacancy')" style="width: 20%">
                <template #editor="{ data, field }">
                    <Link v-if="data.vacancy_id" :href="hasPermission('edit-vacancy') === true ? route('vacancy.edit', data.vacancy_id) : route('vacancy.show', data.vacancy_id)"><p class="text-sm font-weight-bold mb-0">{{ data.vacancy_title }}</p></Link>
                </template>
                <template #body="{ data, field }" >
                    <Link v-if="data.vacancy_id" :href="hasPermission('edit-vacancy') === true ? route('vacancy.edit', data.vacancy_id) : route('vacancy.show', data.vacancy_id)"><p class="text-sm font-weight-bold mb-0">{{ data.vacancy_title }}</p></Link>
                </template>
            </Column>
            <Column field="work_status" :header="$t('leads.status')" style="width: 20%">
                <template #editor="{ data, field }">
                    <span v-if="data.work_status == 'fired'" class="material-symbols-outlined text-[#dc2626]" translate="no">person_off</span>
                    <span v-if="data.work_status == 'worked'" class="material-symbols-outlined text-[#ca8a04]" translate="no">work_alert</span>
                    <span v-if="data.work_status == 'confirmed'" class="material-symbols-outlined text-[#16a34a]" translate="no">done_outline</span>
                    <span v-if="data.work_status == 'working'" class="material-symbols-outlined text-[#2563eb]" translate="no">work</span>
                </template>
                <template #body="{ data, field }">
                    <span v-if="data.work_status == 'fired'" class="material-symbols-outlined text-[#dc2626]" translate="no">person_off</span>
                    <span v-if="data.work_status == 'worked'" class="material-symbols-outlined text-[#ca8a04]" translate="no">work_alert</span>
                    <span v-if="data.work_status == 'confirmed'" class="material-symbols-outlined text-[#16a34a]" translate="no">done_outline</span>
                    <span v-if="data.work_status == 'working'" class="material-symbols-outlined text-[#2563eb]" translate="no">work</span>
                </template>
            </Column>
            <Column :rowEditor="true" style="width: 10%; min-width: 8rem" bodyStyle="text-align:center"></Column>
            <Column field="action" style="width: 20%">
                <template #editor="{ data, field }"></template>
                <template #body="{ data, field }">
                    <template v-if="data.work_status == 'worked'">
                        <Button  @click="handleConfirm(data.id)" icon="pi pi-check" aria-label="Save" severity="success"  />
                    </template>
                    <template v-if="data.work_status == 'confirmed'">
                        <Button @click="handleCancel(data.id)" icon="pi pi-times" aria-label="Save" severity="danger"  />
                    </template>
                </template>
            </Column>
        </DataTable>
    </div>
</template>

<script>
import { Link, Head, router } from "@inertiajs/vue3";
import PrimeLayout from "../../Layouts/PrimeLayout.vue";
import { usePermissions } from '../../Composables/usePermissions';
import { useToast } from "vue-toastification";
import { useConfirm } from 'primevue/useconfirm';
import { trans } from 'laravel-vue-i18n';
import moment from 'moment';


export default {
    layout: PrimeLayout,
    components: {
        Link, Head
    },
    props: {
        leads: Object,
        searchName: {
            type: String,
            required: false,
            default: null
        }
    },
    setup(props) {
        const { hasRole, hasPermission } = usePermissions();
        const confirmPopup = useConfirm();
        const toast = useToast();

        for (const key in props.leads) {
            if (props.leads.hasOwnProperty(key)) {
                const lead = props.leads[key];
                if (lead.recruited_date) {
                    lead.recruited_date = new Date(lead.recruited_date);
                }
                if (lead.first_workday) {
                    lead.first_workday = new Date(lead.first_workday);
                }
                if (lead.leave_date) {
                    lead.leave_date = new Date(lead.leave_date);
                }
            }
        }
    
        return { hasRole, hasPermission, confirmPopup, toast };
    },
    data() {
        return {
            editingRows: [],
            search: this.searchName,
        }
    },
    methods: {
        fdt(date, format='DD.MM.YYYY'){
            if (date){
                return moment(date).format(format);
            }
            return '';
        },
        handleChange(id, field, newValue) {

            let newData = null;
            for (let index = 0; index < this.editingRows.length; index++) {
                const editRow = this.editingRows[index];
                if (editRow.id === id) {
                    newData = editRow;
                }
            }

            newData[field] = newValue;

            if (field == "first_workday" || field == "leave_date" || field == "worked_days") {
                this.calcRequest(this, newData, field);
            } 
        },
        onRowEditSave(event) {
            let { newData, index } = event;
        
            if (newData.worked_confirmed == 1) {
                this.toast.error(trans('leads.error_lead_confirmed'));
            } else {
                this.save(newData);
            }
        },

        prepare(lead_statistic) {
            lead_statistic.first_workday = this.fdt(lead_statistic.first_workday, 'YYYY-MM-DD');
            lead_statistic.leave_date = this.fdt(lead_statistic.leave_date, 'YYYY-MM-DD');
            lead_statistic.recruited_date = this.fdt(lead_statistic.recruited_date, 'YYYY-MM-DD');
            lead_statistic.worked_days = parseInt(lead_statistic.worked_days);

            return lead_statistic;
        },

        save(lead_statistic) {
            axios.post(route('moderation.leads.update', lead_statistic.id), this.prepare(lead_statistic))
                .then(response => {
                    this.toast.success(response.data.success, { timeout: 2000 });
                    this.updateLeadById(response.data.lead_statistic.id, response.data.lead_statistic);
                })
                .catch(error => {
                    this.toast.error('Ошибка', { timeout: 2000 });
                });
        },

        /**
         * Подтверждение отработки
         * @param id 
         */
        handleConfirm(id) {
            axios.post(route('moderation.leads.confirm', id), {
                worked_confirmed: true
            }).then(response => {
                this.toast.success(response.data.success, { timeout: 2000 });
                this.updateLeadById(id, response.data.lead_statistic);
            });
        },

        /**
         * Отмена подтверждения отработки
         * @param id 
         */
        handleCancel(id) {
            axios.post(route('moderation.leads.confirm', id), {
                worked_confirmed: false
            }).then(response => {
                this.toast.success(response.data.success, { timeout: 2000 });
                this.updateLeadById(id, response.data.lead_statistic);
            });
        },

        updateLeadById(id, lead_statistic)
        {
            for (let index = 0; index < this.leads.length; index++) {
                const lead = this.leads[index];
                if (lead.id == lead_statistic.id) {
                    lead_statistic.first_workday = lead_statistic.first_workday ? new Date(lead_statistic.first_workday) : null;
                    lead_statistic.leave_date = lead_statistic.leave_date ? new Date(lead_statistic.leave_date) : null;
                    lead_statistic.recruited_date = lead_statistic.recruited_date ? new Date(lead_statistic.recruited_date) : null;

                    this.leads[index] = lead_statistic;
                    break;
                }
            }
        },

        getLeadById(id) {
            for (let index = 0; index < this.leads.length; index++) {
                const lead = this.leads[index];
                if (lead.id == id) {
                    return lead;
                }
            }
        }
    },
    watch: {
        search(val) {
            let query = {};
            if (val) {
                query.search = val;
            }
            router.get(route('moderation.leads'), query, {
                preserveState: true
            });
        }
    },
    created() {
        this.calcRequest = _.debounce((vm, data, field_name) => {
            axios.post(route('moderation.leads.calc', data.id), {
                changed_field_name: field_name,
                first_workday: this.fdt(data.first_workday, 'YYYY-MM-DD'),
                worked_days: parseInt(data.worked_days),
                leave_date: this.fdt(data.leave_date, 'YYYY-MM-DD'),
            }).then(response => {
                data.first_workday = response.data.first_workday;
                data.worked_days = response.data.worked_days;
                data.leave_date = response.data.leave_date;

                for (let index = 0; index < this.editingRows.length; index++) {
                    const editRow = this.editingRows[index];
                    if (editRow.id === data.id) {
                        this.editingRows[index] = data;
                    }
                }
            });
        }, 500);
    },
}
</script>
<template>
    <Head :title="$t('show')"/>

    <Toolbar class="mb-4 !border-none sm:!flex !hidden">
        <template #start id="tollbar-start">
            <div class="flex items-center gap-2 font-medium text-xl inline-flex tems-baseline">
                <div class="flex flex-row">
                    <i  v-tooltip="$t('vacancies.active')" class="pi !text-[1.35rem]" :class="{ 'pi-check-circle text-green-500 ': vacancy.active, 'pi-times-circle text-red-500': !vacancy.active }"></i>
                </div>
                <div>
                    {{ vacancy.title }}
                </div>
                <div class="flex flex-row">
                    <span v-if="vacancy.hot" v-tooltip="$t('vacancies.hot')" class="material-symbols-outlined text-rose-400 block float-left mt-[-2px]" translate="no">mode_heat</span>
                </div>
            </div>
        </template>

        <template #end>
            <Button :label="$t('Go back')" @click.prevent="goBack();"></Button>
        </template>
    </Toolbar>

    <div class="p-toolbar p-component !border-none sm:!hidden">
        <div class="flex flex-grow flex-col">
            <div class="flex justify-between flex-row">
                <div class="flex items-center gap-2">
                    <i  v-tooltip="$t('vacancies.active')" class="pi !text-[1.35rem] mt-[2px]" :class="{ 'pi-check-circle text-green-500 ': vacancy.active, 'pi-times-circle text-red-500': !vacancy.active }"></i>
                    <span v-if="vacancy.hot" v-tooltip="$t('vacancies.hot')" class="material-symbols-outlined mt-[-2px] text-rose-400" translate="no">mode_heat</span>
                </div>
                <div class="flex items-center gap-2">
                    <span v-tooltip.top="$t('vacancies.udt')" class="material-symbols-outlined" :class="vacancy.udt ? 'text-violet-600' : 'text-[#64748b]'" translate="no">forklift</span>
                    <span v-tooltip.top="$t('vacancies.medical_book')" class="material-symbols-outlined" :class="vacancy.udt ? 'text-red-600' : 'text-[#64748b]'" translate="no">medical_information</span>
                    <span v-tooltip.top="$t('vacancies.experience')" class="material-symbols-outlined" :class="vacancy.experience ? 'text-sky-600' : 'text-[#64748b]'" translate="no">work_history</span>
                    <span v-tooltip.top="$t('vacancies.male')" class="material-symbols-outlined" :class="vacancy.allowed_genders.includes(1) ? 'text-blue-600' : 'text-[#64748b]'" translate="no">male</span>
                    <span v-tooltip.top="$t('vacancies.female')" class="material-symbols-outlined" :class="vacancy.allowed_genders.includes(2) ? 'text-pink-600' : 'text-[#64748b]'" translate="no">female</span>
                    <span v-tooltip.top="$t('vacancies.both')" class="material-symbols-outlined" :class="vacancy.allowed_genders.includes(3) ? 'text-indigo-600' : 'text-[#64748b]'" translate="no">family_restroom</span>
                </div>
            </div>
            <Divider />
            <div class="font-medium text-xl">
                {{ vacancy.title }}
            </div>
        </div>
    </div>

    <Fluid>
        <div class="flex mt-4 sm:mt-8">
            <Tabs value="0" class="w-full" scrollable>
                <TabList class="rounded-md">
                    <Tab value="0" as="div" class="flex items-center gap-2">
                        <span class="material-symbols-outlined" translate="no">home</span>
                        <span>{{ $t('vacancies.parameters') }}</span>
                    </Tab>
                    <Tab value="1" as="div" class="flex items-center gap-2">
                        <span class="material-symbols-outlined" translate="no">description</span>
                        <span>{{ $t('vacancies.descriptions') }}</span>
                    </Tab>
                    <Tab value="2">
                        <div class="flex items-center gap-2">
                            <span class="material-symbols-outlined" translate="no">imagesmode</span>
                            <span>{{ $t('vacancies.photo') }}</span>
                            <Badge :value="vacancy.files ? Object.keys(vacancy.files).length : 0" class="self-end" />
                        </div>
                    </Tab>
                </TabList>

                <TabPanels class="!bg-transparent !p-0">
                    <TabPanel value="0">
                        <div class="mt-4 sm:mt-8">
                            <div class="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-3 gap-4">
                                <div class="!p-4 !m-0 card">
                                    <div class="grid grid-cols-1 gap-y-7">
                                        <div class="flex justify-between">
                                            <div class="text-xl mr-2">
                                                {{ $t('vacancies.requirements') }}
                                            </div>
                                            <div class="flex-wrap lg:gap-1 sm:!flex !hidden">
                                                <span v-tooltip.top="$t('vacancies.udt')" class="material-symbols-outlined" :class="vacancy.udt ? 'text-violet-600' : 'text-[#64748b]'" translate="no">forklift</span>
                                                <span v-tooltip.top="$t('vacancies.medical_book')" class="material-symbols-outlined" :class="vacancy.udt ? 'text-red-600' : 'text-[#64748b]'" translate="no">medical_information</span>
                                                <span v-tooltip.top="$t('vacancies.experience')" class="material-symbols-outlined" :class="vacancy.experience ? 'text-sky-600' : 'text-[#64748b]'" translate="no">work_history</span>
                                                <span v-tooltip.top="$t('vacancies.male')" class="material-symbols-outlined" :class="vacancy.allowed_genders.includes(1) ? 'text-blue-600' : 'text-[#64748b]'" translate="no">male</span>
                                                <span v-tooltip.top="$t('vacancies.female')" class="material-symbols-outlined" :class="vacancy.allowed_genders.includes(2) ? 'text-pink-600' : 'text-[#64748b]'" translate="no">female</span>
                                                <span v-tooltip.top="$t('vacancies.both')" class="material-symbols-outlined" :class="vacancy.allowed_genders.includes(3) ? 'text-indigo-600' : 'text-[#64748b]'" translate="no">family_restroom</span>
                                            </div>
                                        </div>
                                        <div class="flex gap-4">
                                            <TextInput :label="$t('vacancies.age')" v-model="vacancy.min_age" :readonly="true" />
                                            <TextInput v-model="vacancy.max_age" :readonly="true" />
                                            <TextInput :label="$t('vacancies.number_of_workplaces_short')" v-model="vacancy.number_of_workplaces" :readonly="true" />
                                        </div>
                                        <TextInput :label="$t('vacancies.position')" v-model="vacancy.position" :readonly="true" />
                                        <TextInput :label="$t('Citizenship')" v-model="vacancy.citizenships" :readonly="true" />
                                        <Fieldset>
                                            <template #legend>
                                                <span class="text-xl flex">
                                                    {{ $t('vacancies.special_requirements') }} 
                                                    <span class="material-symbols-outlined cursor-pointer block ml-2 text-gray-500" @click.prevent="copyVal('special_requirements')" translate="no">content_copy</span>
                                                </span>
                                            </template>
                                            <span v-html="vacancy.special_requirements"></span>
                                        </Fieldset>
                                    </div>
                                </div>
                                <div class="!p-4 !m-0 card">
                                    <div class="grid grid-cols-1 gap-y-7">
                                        <div class="text-xl">
                                            {{ $t('vacancies.workplace') }}
                                        </div>
                                        <TextInput :label="$t('Facility')" v-model="vacancy.facility" :readonly="true" />
                                        <template v-if="hasPermission('show-vacancy-company')">
                                            <TextInput :label="$t('Company')" v-model="vacancy.company" :readonly="true" />
                                        </template>
                                        <TextInput :label="$t('vacancies.work_city')" v-model="vacancy.work_city" :readonly="true" />
                                        <TextInput :label="$t('vacancies.documents_city')" v-model="vacancy.documents_city" :readonly="true" />
                                        <TextInput :label="$t('vacancies.closest_city')" v-model="vacancy.closest_city" :readonly="true" />
                                        <TextInput :label="$t('vacancies.closest_city_km')" v-model="vacancy.closest_city_km" :readonly="true" />
                                    </div>
                                </div>
                                <div>
                                    <div class="!p-4 card !mb-4 grid grid-cols-1 gap-y-7">
                                        <div class="text-xl">
                                            {{ $t('vacancies.salary') }}
                                        </div>
                                        <div class="grid grid-cols-2 gap-x-4">
                                            <div>
                                                <TextInput :label="$t('vacancies.base_rate_short')" v-model="vacancy.base_rate" :readonly="true" />
                                            </div>
                                            <div>
                                                <TextInput :label="$t('vacancies.young_rate_short')" v-model="vacancy.young_rate" :readonly="true" />
                                            </div>
                                        </div>
                                        <div class="grid grid-cols-2 gap-x-4">
                                            <div>
                                                <TextInput :label="$t('vacancies.student_rate_short')" v-model="vacancy.student_rate" :readonly="true" />
                                            </div>
                                            <div>
                                                <TextInput :label="$t('vacancies.avg_salary')" v-model="vacancy.avg_salary" :readonly="true" />
                                            </div>
                                        </div>
                                    </div>
                                    <div class="!p-4 card grid grid-cols-1 gap-y-7">
                                        <div class="text-xl">
                                            {{ $t('vacancies.conditions') }}
                                        </div>
                                        <TextInput :label="$t('vacancies.contract_type')" v-model="vacancy.contract_type" :readonly="true" />
                                        <TextInput :label="$t('vacancies.contract_type')" v-model="vacancy.preferred_job" :readonly="true" />
                                        <Fieldset>
                                            <template #legend>
                                                <span class="text-xl flex">
                                                    {{ $t('vacancies.conditions_description') }}
                                                    <span class="material-symbols-outlined cursor-pointer block ml-2 text-gray-500" @click.prevent="copyVal('conditions')" translate="no">content_copy</span>
                                                </span>
                                            </template>
                                            <span v-html="vacancy.conditions"></span>
                                        </Fieldset>
                                    </div>
                                </div>
                                <div class="!p-4 card !m-0">
                                    <div class="grid grid-cols-1 gap-y-7">
                                        <div class="flex justify-between">
                                            <div class="text-xl mr-2">
                                                {{ $t('vacancies.housing') }}
                                            </div>
                                            <div class="flex flex-wrap lg:gap-1">
                                                <span v-tooltip.left="$t('vacancies.housing_compensation')" class="material-symbols-outlined" :class="vacancy.housing_compensation ? 'text-[#b77305]' : 'text-[#64748b]'" translate="no">real_estate_agent</span>
                                            </div>
                                        </div>
                                        <TextInput :label="$t('vacancies.housing_type')" v-model="vacancy.housing_type" :readonly="true" />
                                        <div class="grid grid-cols-2 gap-x-4">
                                            <div>
                                                <TextInput :label="$t('vacancies.housing_cost')" v-model="vacancy.housing_cost" :readonly="true" />
                                            </div>
                                            <div>
                                                <TextInput :label="$t('vacancies.student_housing_cost')" v-model="vacancy.student_housing_cost" :readonly="true" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="!p-4 card !m-0">
                                    <div class="grid grid-cols-1 gap-y-7">
                                        <div class="flex justify-between">
                                            <div class="text-xl mr-2">
                                                {{ $t('vacancies.work_hours') }}
                                            </div>
                                            <div class="flex flex-wrap lg:gap-1">
                                                <span v-tooltip.left="$t('vacancies.night_shifts')" class="material-symbols-outlined" :class="vacancy.night_shifts ? 'text-rose-400' : 'text-[#64748b]'" translate="no">dark_mode</span>
                                            </div>
                                        </div>
                                        <Fieldset>
                                            <template #legend>
                                                <span class="text-xl flex">
                                                    {{ $t('vacancies.work_schedule_description') }}
                                                    <span class="material-symbols-outlined cursor-pointer block ml-2 text-gray-500" @click.prevent="copyVal('work_schedule_text')" translate="no">content_copy</span>
                                                </span>
                                            </template>
                                            <span v-html="vacancy.work_schedule_text"></span>
                                        </Fieldset>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </TabPanel>
                    <Tabpanel value="1">
                        <div class="mt-8">
                            <div class="!p-4 card !mb-4">
                                <div class="text-xl flex">
                                    {{ $t('vacancies.vacancy_description') }}
                                </div>
                                <div>
                                    <Fieldset>
                                        <template #legend>
                                            <div class="flex">
                                                <Select v-model="template" :options="templates" optionLabel="label" optionValue="value" />
                                                <span class="material-symbols-outlined cursor-pointer flex self-center ml-2 text-gray-500" @click.prevent="copyDesc()" translate="no">content_copy</span>
                                            </div>
                                        </template>
                                        <span v-html="vacancy.description"></span>
                                    </Fieldset>
                                </div>
                            </div>
                            <div class="!p-4 card !mb-4">
                                <Fieldset>
                                    <template #legend>
                                        <span class="text-xl flex">
                                            {{ $t('vacancies.recruiter_description') }}
                                            <span class="material-symbols-outlined cursor-pointer block ml-2 text-gray-500" @click.prevent="copyVal('recruiter_description')" translate="no">content_copy</span>
                                        </span>
                                    </template>
                                    <span v-html="vacancy.recruiter_description"></span>
                                </Fieldset>
                            </div>
                            <div class="!p-4 card !mb-4">
                                <Fieldset>
                                    <template #legend>
                                        <span class="text-xl flex">
                                            {{ $t('v_companies.recruiter_description') }}
                                            <span class="material-symbols-outlined cursor-pointer block ml-2 text-gray-500" @click.prevent="copyVal('company_recruiter_description')" translate="no">content_copy</span>
                                        </span>
                                    </template>
                                    <span v-html="vacancy.company_recruiter_description"></span>
                                </Fieldset>
                            </div>
                        </div>
                    </Tabpanel>
                    <Tabpanel value="2">
                        <div class="mt-8">
                            <ImagesView :files="vacancy.files"/>
                        </div>
                    </Tabpanel>
                </TabPanels>
            </Tabs>
        </div>
    </Fluid>
</template>


<script>
import { Head } from '@inertiajs/vue3'
import { usePermissions } from '../../Composables/usePermissions';
import PrimeLayout from '../../Layouts/PrimeLayout.vue';
import ImagesView from "../../Components/PrimeForm/ImagesView.vue";
import { TextInput, InputWrap } from "../../Components/PrimeForm";

export default {
    layout: PrimeLayout,
    components: {
        Head, ImagesView, TextInput, InputWrap
    },
    props: {
        vacancy: Object,
        preferred_jobs: Array,
        templates: Array
    },
    setup(props) {
        return usePermissions();
    },
    data() {
        return {
            description: this.vacancy.description,
            template: 0
        }
    },
    methods: {
        goBack() {
            window.history.back();
        },
        makePreview(template_id) { 
            axios.post(route('template.make'), { vacancy_id: this.vacancy.id, template_id: template_id }).then((response) => {
                this.description = response.data.text;
            });
        },
        copyVal(field) {
            if(this.vacancy[field])
                this.copyRichText(this.vacancy[field]);
        },
        copyDesc(field) {
            this.copyRichText(this.description);
        },
        copyRichText(html) {
            const htmlContent = html;
            // Create a temporary element to hold the HTML content
            const tempElement = document.createElement("div");
            tempElement.innerHTML = htmlContent;
            document.body.appendChild(tempElement);
            // Select the HTML content
            const range = document.createRange();
            range.selectNode(tempElement);
            // Copy the selected HTML content to the clipboard
            const selection = window.getSelection();
            selection.removeAllRanges();
            selection.addRange(range);
            document.execCommand("copy");
            selection.removeAllRanges();
            document.body.removeChild(tempElement);
        }
    },
    watch: {
        template(newVal) {
            if (newVal > 0) {
                this.makePreview(newVal);
            }
            else {
                this.description = this.vacancy.description;
            }
        }
    }
}
</script>
class FilterBuilder {
    constructor(filters, filterData, filterLogic) {
        this.filters = filters;
        this.filterData = filterData;
        this.filterLogic = filterLogic;

        this.numericMathModes = ['lt', 'lte', 'gt', 'gte'];
        this.stringMatchModes = ['startsWith', 'endsWith'];
    }

    build() {
        for (let [key, params] of Object.entries(this.filters)) {
            //Простой фильтр
            if (params.hasOwnProperty('matchMode') && params.hasOwnProperty('value')) {

                if (this.filterData.hasOwnProperty(key) && this.filterData?.[key]) {
                    let filterValue = this.filterData[key];
                    //Для множественных значений простого фильтра
                    if (params.matchMode == 'in') {
                        if (typeof filterValue == 'string' && filterValue.includes(',')) {
                            filterValue = filterValue.split(',');
                        }
                        else {
                            filterValue = [filterValue];
                        }
                    }
                    
                    params.value = this.#convertFilterValue(filterValue);
                }

            }
            //Сложный фильтр
            else if (params.hasOwnProperty('operator') && params.hasOwnProperty('constraints')) {
                if (this.filterData.hasOwnProperty(key) && this.filterData?.[key]) {
                    let filterValue = this.filterData[key];
                    const defaultMatchMode = params.constraints[0].matchMode;

                    //Для комплексных значений сложного фильтра
                    if (typeof filterValue == 'string' && filterValue.includes(',')) {
                        params.constraints = [];
                        filterValue = filterValue.split(',');
                        filterValue.forEach((singleValue) => {
                            params.constraints.push(this.#makeFilterConstraint(singleValue, defaultMatchMode));
                        });
                    }
                    //Для одинарных значений сложного фильтра
                    else {
                        params.constraints = [this.#makeFilterConstraint(filterValue, defaultMatchMode)]
                    }

                    //Смена логического оператора сопоставления
                    if (this.filterLogic && this.filterLogic.hasOwnProperty(key)) {
                        params.operator = this.filterLogic[key];
                    }
                }
            }
            this.filters[key] = params;
        }

        return this.filters;
    }

    #convertFilterValue(value) {
        //Для множественных значений простого фильтра
        if (Array.isArray(value)) {
            let result = [];
            value.forEach((single) => {
                single = this.#convertFilterValue(single);
                result.push(single);
            });
            return result;
        }

        if (value == 'true')
            return true;
        if (value == 'false')
            return false;

        const parsedNumber = parseFloat(value);
        if (!isNaN(parsedNumber))
            return parsedNumber;
        return value;
    }

    #makeFilterConstraint(value, defaultMode) {
        const matchModes = {
            notEquals: '<>',
            gte: '>=',
            lte: '<=',
            lt: '<',
            gt: '>',
            equals: '=',
            notContains: '!'
        };

        if (typeof value == 'string') {
            //Модификация значения для текстовых фильтров
            if (value.includes('%')) {
                const regexContains = /^%.*%$/gm;
                const regexStartsWith = /^[^%]*%$/gm;
                const regexEndsWith = /^%[^%]*$/gm;

                if (regexContains.test(value)) {
                    return {
                        value: value.replaceAll('%', ''),
                        matchMode: 'contains'
                    }
                }

                if (regexEndsWith.test(value)) {
                    return {
                        value: value.replaceAll('%', ''),
                        matchMode: 'endsWith'
                    }
                }

                if (regexStartsWith.test(value)) {
                    return {
                        value: value.replaceAll('%', ''),
                        matchMode: 'startsWith'
                    }
                }
            }
            //Модификация значений для числовых фильтров
            else {
                for (let [key, operator] of Object.entries(matchModes)) {
                    if (value.startsWith(operator)) {
                        return {
                            value: this.#convertFilterValue(value.replace(operator, '')),
                            matchMode: key
                        }
                    }
                }
            }
        }

        return {
            value: this.#convertFilterValue(value),
            matchMode: defaultMode
        };
    }
}

class QueryBuilder {
    constructor(filters) {
        this.filters = filters;
        this.numericMathModes = ['lt', 'lte', 'gt', 'gte'];
        this.stringMatchModes = ['startsWith', 'endsWith', 'notContains', 'contains'];
    }
    
    build() {
        let query = {};
        let logic = {};

        for (const [key, params] of Object.entries(this.filters)) {
            //Простой фильтр
            if (params.hasOwnProperty('matchMode') && params.hasOwnProperty('value')) {
                query = { ...query, ...this.#simpleFilterQuery(key, params) };
            }
            //Сложный фильтр
            else if (params.hasOwnProperty('operator') && params.hasOwnProperty('constraints')) {
                const result = this.#complexFilterQuery(key, params);
                if (result) {
                    query = { ...query, ...result };
                    //Смена логического оператора сопоставления
                    if (params.operator != 'and' && params.constraints?.length > 1) {
                        logic[key] = params.operator;
                    }
                }
            }
        }

        return {query:query, logic:logic};
    }

    #simpleFilterQuery(key, params) {
        let obj = {};
        return {
            [key]: this.#setFilterValue(null, params)
        };
    }

    #complexFilterQuery(key, params) {
        let values = null;
        params.constraints.forEach((singleParams) => {
            values = this.#setFilterValue(values, singleParams);
        });
        if (values) {
            return {
                [key]: values
            };
        }
    }

    #setFilterValue(values, params) {
        if (params.value !== null) {
            const matchMode = params.matchMode;
            let value = params.value;

            //Модификация значения для текстовых фильтров
            if (this.#isStringMode(matchMode)) {
                value = this.#setStringValue(params);
            }
            //Модификация значений для числовых фильтров
            else if (this.#isNumericMode(matchMode)) {
                value = this.#setNumericValue(params);
            }
            //Модификация значений для общих фильтров
            else {
                const matchModes = {
                    notEquals: '<>',
                };

                const prefix = matchModes[params.matchMode] ?? '';
                value = prefix + params.value;
            }

            if (values === null) {
                values = value;
            }
            else {
                values = values + ',' + value;
            }
        }
        return values;
    }

    #setNumericValue(params) {
        const matchModes = {
            lt: '<',
            lte: '<=',
            gt: '>',
            gte: '>=',
        };

        const prefix = matchModes[params.matchMode] ?? '';
        const value = prefix + params.value;

        return value;
    }
    
    #setStringValue(params) {
        switch (params.matchMode) {
            case 'startsWith':
                return params.value + '%';
            case 'endsWith':
                return '%' + params.value;
            case 'contains':
                return '%' + params.value + '%';
            case 'notContains':
                return '!' + params.value;
        }
    }

    #isNumericMode(matchMode) {
        return this.numericMathModes.includes(matchMode);
    }

    #isStringMode(matchMode) {
        return this.stringMatchModes.includes(matchMode);
    }
}

export function queryToFilter(filters, filterData, filterLogic) {
    const filterBuilder = new FilterBuilder(filters, filterData, filterLogic);
    return filterBuilder.build();
}

export function filterToQuery(filters) {
    const queryBuilder = new QueryBuilder(filters);
    return queryBuilder.build();
}
<template>
    <div class="!mb-4">    
        <Tabs v-if="!small" v-model:value="active">
            <TabList pt:tabList:class="!grid grid-flow-row md:grid-flow-col justify-stretch !border-none !rounded" pt:activeBar:class="!hidden">
                <template v-for="(stage, sid) in stages">
                    <Tab class="flex items-center justify-center gap-2 !rounded !border-none !p-2.5" :class="active == sid ? stage.background + ' ' + stage.text : ''" :value="sid">
                        <span class="!font-medium whitespace-nowrap">{{ stage.title }}</span>
                    </Tab>
                </template>
            </TabList>
        </Tabs>
        <Select v-else 
            v-model="active" 
            :options="stages" 
            optionLabel="title" 
            optionValue="sid" 
            :class="`!border-none 
            ${activeObject.background}`" 
            :labelClass="`${activeObject.text}`" 
            :pt:dropdown:class="`${activeObject.text}`"
        />
    </div>
</template>

<script>
import { usePermissions } from '../../Composables/usePermissions';

export default {
    props: {
        stage: {
            type: Number,
            required: true
        },
        small: {
            type: Boolean,
            required: false,
            default: false
        }
    },
    setup(props) {
        //TODO:Сделать притяжку стадий из 1 места
        const stages = [
            {
                title: 'Новый',
                text: '!text-white',
                background: '!bg-slate-500',
                sid: 0
            },
            {
                title: 'В работе',
                text: '!text-white',
                background: '!bg-blue-500',
                sid: 1
            },
            {
                title: 'Подтверждение',
                text: '!text-white',
                background: '!bg-orange-500',
                sid: 2
            },
            {
                title: 'Подан',
                text: '!text-white',
                background: '!bg-purple-500',
                sid: 3
            },
            {
                title: 'Срекрутирован',
                text: '!text-white',
                background: '!bg-green-500',
                sid: 4,
            },
            {
                title: 'Ожидание',
                text: '!text-white',
                background: '!bg-black',
                sid: 5
            },
            {
                title: 'Отказ',
                text: '!text-white',
                background: '!bg-red-500',
                sid: 6
            },
        ];

        const { hasRole, hasPermission } = usePermissions();

        return { stages, hasRole, hasPermission };
    },
    computed: {
        active: {
            get() {
                return this.stage;
            },
            set(value) {
                this.$emit('update:stage', value);
            }
        },
        activeObject() {
            return this.stages[this.active];
        }
    }
}
</script>